import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Logo from "../images/logo.png";
import { useState } from "react";
import { Button, Input, Row, Space, message } from "antd";
import { FiArrowLeftCircle } from "react-icons/fi";

const SignInComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const executeSignIn = async (event: React.MouseEvent) => {
    event.preventDefault();
    const result = await auth.signIn(username, password);
    if (result.success) {
      message.success("successfully signed in!");
      navigate({ pathname: "/" });
    } else {
      message.error(
        "Sign In failed, please check your credentials and try again."
      );
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);

    if (value.trim() === "") {
      setUsernameError("Username is required");
    } else {
      setUsernameError("");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value.trim() === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };

  return (
    <>
      <Row
        style={{ width: "100%", height: "100vh" }}
        justify={"center"}
        align={"middle"}
      >
        <Button
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            margin: "22px",
            padding: 0,
            width: "94px",
            overflow: "visible",
            color: "#999999",
            fontSize: "18px",
          }}
          type="text"
          onClick={() => navigate(-1)}
        >
          <Row align={"middle"} justify={"space-evenly"}>
            <FiArrowLeftCircle size={"44px"} /> Back
          </Row>
        </Button>
        <Row style={{ width: "324px" }} justify={"center"}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "30px" }}
            >
              <img src={Logo} style={{ width: "150px" }} alt={"logo"} />
            </Row>
            <Input
              placeholder="Username"
              style={{ textAlign: "center" }}
              className={`text-center border ${usernameError ? "border-lifecache-color-red" : ""} ${
                usernameError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={username}
              onChange={handleUsernameChange}
            />
            {usernameError && (
              <span className="text-lifecache-color-red text-sm">{usernameError}</span>
            )}
            <Input
              placeholder="Password"
              style={{ textAlign: "center" }}
              className={`text-center border ${
                passwordError ? "border-lifecache-color-red" : ""
              } ${
                passwordError ? "bg-lifecache-color-light-pink" : ""
              }`}
              disabled={!username}
              value={password}
              type="password"
              onChange={handlePasswordChange}
            />
            {passwordError && (
              <span className="text-lifecache-color-red text-sm">{passwordError}</span>
            )}
          </Space>
          <Button
            shape="round"
            style={{ marginTop: 30, width: "134px" }}
            onClick={executeSignIn}
            disabled={!username || !password || usernameError !== "" || passwordError !== ""}
          >
            <b>Login</b>
          </Button>
        </Row>
      </Row>
    </>
  );
};

export const SignIn = SignInComponent;
