import Map, { MapRef, NavigationControl } from "react-map-gl";
import { useGeolocated } from "react-geolocated";
import { useContext, useEffect, useRef, useState } from "react";
import { CurrentLocationMarker } from "./CurrentLocationMarker";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ThemeContext } from "../contexts/ThemeContext";
import Place from "../types/place";
/* eslint-disable import/no-webpack-loader-syntax */
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface MapViewSimpleProps {
  selectedPlace?: Place | null;
  lat?: number | null;
  lng?: number | null;
  setLatLng: (l: [number, number]) => void;
}

export const MapViewSimple = ({
  selectedPlace,
  setLatLng,
  lat,
  lng,
}: MapViewSimpleProps) => {
  const { theme } = useContext(ThemeContext);
  const [viewState, setViewState] = useState({
    latitude: lat || 55.97474,
    longitude: lng || -108.80249,
    zoom: 14,
  });

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 100000,
    });

  const mapRef = useRef<MapRef>();

  useEffect(() => {
    if (selectedPlace) {
      setViewState({
        latitude: selectedPlace.center[1],
        longitude: selectedPlace.center[0],
        zoom: 14,
      });
      setLatLng([selectedPlace.center[1], selectedPlace.center[0]]);
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (!coords) {
      return;
    }

    setViewState({
      latitude: lat || coords.latitude,
      longitude: lng || coords.longitude,
      zoom: 14,
    });
  }, [coords]);

  return (
    <>
      {coords ? (
        <div className="rounded-lg overflow-hidden mt-5" style={{width: '100%', height: 200}}>
          <Map
            // @ts-ignore
            ref={mapRef}
            mapLib={mapboxgl}
            mapboxAccessToken="pk.eyJ1Ijoibml0aGlsYW4tbGlmZWNhY2hlIiwiYSI6ImNsaTBqNTFseTBjZnQzc256cWg4NGl1YzYifQ.KutSc5nIKiQJ9cglVW2fCg"
            {...viewState}
            onMove={(evt) => {
              setViewState(evt.viewState);
            }}
            onClick={(e) => setLatLng([e.lngLat.lat, e.lngLat.lng])}
            mapStyle={
              theme === "dark"
                ? "mapbox://styles/mapbox/dark-v10"
                : "mapbox://styles/mapbox/light-v10"
            }
          >
            {selectedPlace && (
              <CurrentLocationMarker
                latitude={lat || selectedPlace.center[1]}
                longitude={lng || selectedPlace.center[0]}
              />
            )}
            <NavigationControl />
          </Map>
        </div>
      ) : (
        <div>Getting the location data&hellip;</div>
      )}
    </>
  );
};
