import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as SortIcon } from "../assets/svg/sort-icon.svg";

export enum SortOption {
  Latest = 'Latest',
  Oldest = 'Oldest',
  MostLiked = 'Most Liked',
  MostViewed = 'Most Viewed',
}

type MomentSortProps = {
  defaultSortOption: SortOption;
  onSortOptionChanged: (option: SortOption) => void;
};

const MomentSort: React.FC<MomentSortProps> = ({defaultSortOption, onSortOptionChanged }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SortOption>(defaultSortOption);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOption(defaultSortOption);
  }, [defaultSortOption]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option: SortOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSortOptionChanged(option);
  };

  return (
    <div className="relative inline-block text-left z-50" ref={dropdownRef}>
      <div
        className="flex items-center"
        style={{ cursor: "pointer" }}
        id="options-menu"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SortIcon className="w-12" />
      </div>

      {isOpen && (
        <div className="rounded-[10px] border-[1px] border-lifecache-color-white/40 border-solid origin-top-right absolute right-0 mt-3 w-48 bg-lifecache-color-gray/90 ">
          <div className="font-lifecache-body" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {Object.values(SortOption).map((option) => (
              <a
                key={option}
                className={`border-b-[1px] border-b-lifecache-color-white/40 last:border-b-0 border-t-0 border-l-0 border-r-0
                border-solid  block px-4 py-3 text-sm hover:bg-lifecache-color-light-gray hover:text-lifecache-color-black
                ${selectedOption === option ? 'bg-lifecache-color-light-gray text-lifecache-color-black font-semibold' : 'text-lifecache-color-white'}`}
                role="menuitem"
                onClick={() => handleSelect(option as SortOption)}
              >
                {option}{option === SortOption.Latest? ' (Default)' : ''}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MomentSort;
