import {Button, Card, Col, List, Row, Switch} from "antd";
import { useAuth } from "../hooks/useAuth";
import useForwardGeocoding from "../hooks/useForwardGeocoding";
import React, {ChangeEvent, useContext, useRef, useState} from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import Place from "../types/place";

type ProfileProps = {
  fullName?: string;
  location?: string;
  memberSince?: string;
  profilePic?: string;
  bio?: string;
  favorites?: string;
  followers?: string;
  following?: string;
	onUpdateProfile: (fullName: string, location: string, bio: string) => void;
};

export const Profile = ({
  fullName = "John Doe",
  location = "Miami, FL",
  memberSince = "2021",
  profilePic = "https://xsgames.co/randomusers/avatar.php?g=male",

  bio = "Lead Marketer and Brand Consultant for the company. Take advantage of our Exclusive AR Promotions available only through our immersive “Light up the world with Promos” campaign.\n\nTop locations for promotions include Miami, Toronto, Los Angeles, Riyadh, Abu Dhabi and Orlando.\n\nTeleport today!!",
  favorites = "900",
  followers = "10M",
  following = "15k",
	onUpdateProfile,
}: ProfileProps) => {
  const { signOut } = useAuth();
  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeChange = () => {
	const isCurrentDark = theme === "dark";
	setTheme(isCurrentDark ? "light" : "dark");
	localStorage.setItem("default-theme", isCurrentDark ? "light" : "dark");
  };

  const [isEditing, setIsEditing] = useState(false);
	const [tempData, setTempData] = useState({
		fullName,
		location,
		bio,
	});

	const [fullNameError, setFullNameError] = useState('');

	const [selectedPlace, setSelectedPlace] = useState<Place>({
		place_name: "",
		center: [0, 0],
	});

	const address = useForwardGeocoding({
		initialValue: "",
	});

	const formattedBio = bio.split('\n').map((str, index, array) =>
	  index === array.length - 1 ? str : <>
		{str}
		<br />
	  </>
  );

	const handleUpdateProfile = async () => {
		onUpdateProfile(
			tempData.fullName,
			tempData.location,
			tempData.bio
		);

		setIsEditing(false);
	};

	const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setTempData({ ...tempData, fullName: value });

		if (value.trim() === '') {
			setFullNameError('Name is required');
		} else {
			setFullNameError('');
		}
	};

	const handleSelectedPlace = (place: Place) => {
		// setSelectedPlace(place);
		setTempData(prev => ({...prev, location: place.place_name}));
	};

	const bioTextareaRef = useRef<HTMLTextAreaElement>(null);

	const handleBioChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setTempData(prev => ({...prev, bio: e.target.value}))
		adjustBioTextareaHeight();
	};

	const adjustBioTextareaHeight = () => {
		if (bioTextareaRef.current) {
			bioTextareaRef.current.style.height = 'auto';
			bioTextareaRef.current.style.height = bioTextareaRef.current.scrollHeight + 'px';
		}
	};

	const resetTempData = () => {
		setTempData({
			fullName,
			location,
			bio,
		});
	};

	return (
	<>
	  <div
		className="absolute right-[1.5rem] mt-2 w-96 bg-lifecache-color-gray p-1 rounded-tl-lg rounded-br-lg rounded-bl-lg shadow-lg z-50"
		style={{ top: "65px" }}
	  >

		{!isEditing &&
			<div className="p-4 rounded-lg">
			  <div className="flex flex-row justify-between items-center">
				<section className="flex flex-col">
				  <span className="text-2xl font-bold">{fullName}</span>
				  <span className="">{location}</span>
				  <span>Member since {memberSince}</span>
				</section>
				<section>
				  {/* <Avatar
				className="bg-gray-600 border-2 border-green-500 "
				size={100}
				src={'../assets/images/profile-photo-test.jpg'}
				alt="Profile Picture"
			  /> */}
				</section>
			  </div>

			  <section className="py-4">
				<div>
				  {formattedBio}
				</div>
			  </section>

			  {/* <Row>
				<Col span={24}>
				  <Card
					  className="bg-transparent border-2 border-white"
					  bodyStyle={{ padding: "12px" }}
				  >
					<div className="flex flex-col text-white font-lifecache-title">
					  <span className="self-start">ADS PUSHED</span>
					  <span className="self-end text-4xl">{favorites}</span>
					</div>
				  </Card>
				</Col>
			  </Row>

			  <Row gutter={8} className="mt-3">
				<Col span={12}>
				  <Card
					  className="bg-transparent border-2 border-white"
					  bodyStyle={{ padding: "12px" }}
				  >
					<div className="flex flex-col text-white font-lifecache-title">
					  <span className="self-start">FOLLOWERS</span>
					  <span className="self-end text-4xl">{followers}</span>
					</div>
				  </Card>
				</Col>

				<Col span={12}>
				  <Card
					  className="bg-transparent border-2 border-white"
					  bodyStyle={{ padding: "12px" }}
				  >
					<div className="flex flex-col text-white font-lifecache-title">
					  <span className="self-start">FOLLOWING</span>
					  <span className="self-end text-4xl">{following}</span>
					</div>
				  </Card>
				</Col>
			  </Row> */}
			</div>
		}

		{isEditing &&
			<div className="p-4 rounded-lg">
			  <div className="text-white font-bold mb-2">Edit Profile</div>
			  <div className="flex flex-col mb-2">
					<label className="text-sm font-medium">Name</label>
					<input
						type="text"
						placeholder="Enter your name"
						value={tempData.fullName}
						onChange={handleFullNameChange}
						className={`w-auto h-[24px] bg-transparent text-white font-lifecache-brand
						outline-none border-r-0 border-b-1
						${fullNameError ? 'border-b-lifecache-color-red' : 'border-b-lifecache-color-white/20'}`}
					/>
					{fullNameError && (
						<span className="text-lifecache-color-red text-sm">{fullNameError}</span>
					)}
				</div>

			  <div className="flex flex-col mb-2">
					<label className="text-sm font-medium">Location</label>
					<input
						type="text"
						placeholder="Enter your location"
					  //	{...address}
						value={tempData.location}
						readOnly={true}
						onChange={(e) => setTempData(prev => ({...prev, location: e.target.value}))}
						className="w-auto h-[24px] bg-transparent text-white font-lifecache-brand outline-none border-r-0 border-b-1 border-b-lifecache-color-white/20"
					/>

					{address.suggestions?.length > 0 && (
						<div
							className="absolute w-[22rem] bg-lifecache-color-gray mt-14 rounded-b-lg"
						>
							<List
								className="p-2"
								dataSource={address.suggestions}
								renderItem={(suggestion) => (
									<List.Item
										className="cursor-pointer text-white"
										onClick={() => {
											address.setValue(suggestion.place_name);
											address.setSuggestions([]);
											handleSelectedPlace(suggestion);
										}}
									>
										{suggestion.place_name}
									</List.Item>
								)}
							/>
						</div>
					)}
				</div>

				<div className="flex flex-col mb-2">
					<label className="text-sm font-medium">Bio</label>
					<textarea
						ref={bioTextareaRef}
						name="bio"
						rows={3}
						maxLength={500}
						inputMode={"text"}
						placeholder="Enter your bio here..."
						value={tempData.bio}
						onChange={handleBioChange}
						className="bg-transparent text-white font-lifecache-brand resize-none outline-none border-t-0 border-l-0 border-r-0 border-b-1 border-b-lifecache-color-white/20 overflow-hidden"
						style={{
							minHeight: '72px',
							maxHeight: '200px',
						}}
					/>
					<div className="text-right text-lifecache-color-white/20 mt-2">
						{tempData.bio.length} / 500
					</div>
				</div>
			</div>
		}

		{isEditing &&
			<div className="p-4">
				<div className="flex flex-row place-content-end pb-4">
					<Button
						onClick={handleUpdateProfile}
						className="w-[134px] h-[45px] rounded-[44px] text-[18px] border-none"
						disabled={!tempData.fullName ||  fullNameError !== ""}
					>
						Done
					</Button>
				</div>

				<div className="flex flex-row place-content-end">
					<Button
						onClick={() => {
							setIsEditing(false);
							resetTempData();
						}}
						className="w-[134px] h-[45px] rounded-[44px] text-[18px] bg-lifecache-color-white/20 border-none"
					>
						Cancel
					</Button>
				</div>
			</div>
		}

		{!isEditing &&
			<div className="p-4">
				<div className="flex flex-row place-content-start mb-4">
					<Button
						onClick={() => setIsEditing(true)}
						className="w-[134px] h-[45px] rounded-[44px] text-[18px] border-none"
					>
						Edit Profile
					</Button>
				</div>
				<div className="flex flex-row place-content-start">
					<Button
						onClick={signOut}
						className="w-[134px] h-[45px] rounded-[44px] text-[18px] bg-lifecache-color-white/20 border-none"
					>
						Log Out
					</Button>
				</div>
			</div>
		}

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>

				{!isEditing &&
					<div className="py-4">
						Dark Mode:{" "}
						<Switch
							size="small"
							checked={theme === "dark"}
							onChange={handleThemeChange}
						/>
					</div>
				}
			</div>
	  </div>
	</>
  );
};
