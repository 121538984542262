import React from 'react';
import {Button, Row} from "antd";
import { CloseOutlined } from '@ant-design/icons';

interface ConfirmDialogProps {
  isVisible: boolean;
  title: string;
  description: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
                                                       isVisible,
                                                       title,
                                                       description,
                                                       confirmLabel = 'Confirm',
                                                       cancelLabel = 'Cancel',
                                                       onConfirm,
                                                       onCancel
                                                     }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center rounded-2xl shadow-lg text-white">
      <div className="bg-black">
        <div className="bg-lifecache-color-white/20 p-4 rounded-2xl shadow-lg">
          <div className="flex justify-end">
            <Button
              className="bg-lifecache-color-white/20 text-white/40"
              style={{ border: "inherit" }}
              shape="circle"
              size="small"
              onClick={onCancel}
              icon={<CloseOutlined />}
            />
          </div>

          <span className="text-lifecache-color-white text-2xl">{title}</span>

          <div className="text-white rounded-2xl mt-5">
            <p>{description}</p>
          </div>

          <Row justify={"end"} align={"middle"}>
            <Button
              shape="round"
              style={{ marginTop: 30 }}
              className="bg-lifecache-color-white/20 border-none"
              onClick={onCancel}
            >
              <b>{cancelLabel}</b>
            </Button>

            <Button
              shape="round"
              style={{ marginTop: 30, marginLeft: 10 }}
              className="border-none"
              onClick={onConfirm}
            >
              <b>{confirmLabel}</b>
            </Button>
          </Row>
        </div>
      </div>
    </div>
  );
};
