import React, { useEffect, useState } from "react";
import { NavBar } from "../components/NavBar";
import { Button, Card, Col, Row, Typography, message } from "antd";
import { UserProvideService } from "../services/userService";
import IUserResponse from "../types/user.res";

export const Account = () => {
  const [createUserModal, setCreateUserModal] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<IUserResponse[]>([]);
  const { createForOrg, getAllUsersOrg } = UserProvideService();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const usersRes = await getAllUsersOrg();
    setAllUsers(usersRes);
  };

  const CreateUserModal = () => {
    const [username, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    return (
      <div
        className="z-40 fixed top-10 left-1/2 transform -translate-x-1/2 bg-lifecache-color-black rounded-2xl shadow-lg"
        style={{ width: "600px" }}
      >
        <div className="bg-lifecache-color-white/20 p-8 rounded-2xl shadow-lg">
          <div
            style={{
              fontFamily: "Rubik Mono One",
              fontSize: 20,
              marginTop: -10,
              color: "#F3F3F3",
            }}
          >
            ADD USER
          </div>
          <div
            style={{
              width: "calc(100% + 50px)",
              marginLeft: "-25px",
              borderBottom: "1px solid #FFFFFF1A",
            }}
          ></div>
          <Row
            justify={"center"}
            align={"middle"}
            style={{ marginTop: "15px" }}
          >
            <div
              style={{ width: "100%", marginBottom: 10, fontWeight: "bolder" }}
            >
              Full Name
            </div>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Type Here"
              className="h-[40px]
bg-[#00000066] rounded-[24px]
text-white border-none
outline-none pl-[24px] pr-[24px]
backdrop-blur-sm backdrop-brightness-[100%]
[-webkit-backdrop-filter:blur(4px)_brightness(100%)"
            />
          </Row>
          <Row
            justify={"center"}
            align={"middle"}
            style={{ marginTop: "15px" }}
          >
            <div
              style={{ width: "100%", marginBottom: 10, fontWeight: "bolder" }}
            >
              Username
            </div>
            <input
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Type Here"
              className="h-[40px]
bg-[#00000066] rounded-[24px]
text-white border-none
outline-none pl-[24px] pr-[24px]
backdrop-blur-sm backdrop-brightness-[100%]
[-webkit-backdrop-filter:blur(4px)_brightness(100%)"
            />
          </Row>
          <Row
            justify={"center"}
            align={"middle"}
            style={{ marginTop: "15px" }}
          >
            <div
              style={{ width: "100%", marginBottom: 10, fontWeight: "bolder" }}
            >
              Email
            </div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "100%" }}
              placeholder="Type Here"
              className="h-[40px]
bg-[#00000066] rounded-[24px]
text-white border-none
outline-none pl-[24px] pr-[24px]
backdrop-blur-sm backdrop-brightness-[100%]
[-webkit-backdrop-filter:blur(4px)_brightness(100%)"
            />
          </Row>
          <Row style={{ width: "100%" }} align={"middle"} justify={"end"}>
            <Button
              shape="round"
              style={{
                marginTop: 15,
                width: "134px",
                height: "45px",
                color: "1E1E1E",
                marginRight: 15,
              }}
              onClick={() => setCreateUserModal(false)}
            >
              <b>Cancel</b>
            </Button>
            <Button
              shape="round"
              style={{
                marginTop: 15,
                width: "134px",
                height: "45px",
                color: "1E1E1E",
              }}
              disabled={!email || !username || !fullName}
              onClick={async () => {
                const res = await createForOrg(fullName, username, email);
                if (!res) {
                  message.error(
                    "Something went wrong. Please try again later."
                  );
                } else {
                  message.success("User successfully created");
                  setCreateUserModal(false);
                  loadUsers();
                }
              }}
            >
              <b>Send Invite</b>
            </Button>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <div
        className="flex flex-col items-center justify-start text-white bg-lifecache-color-gray"
        style={{ minHeight: "calc(100vh - 90px)" }}
      >
        <Row
          style={{
            width: "100%",
          }}
          justify={"center"}
        >
          <Col style={{ width: "1048px" }}>
            <div
              style={{
                fontFamily: "Rubik Mono One",
                fontSize: 44,
                marginTop: 10,
              }}
            >
              ACCOUNT SETTINGS
            </div>
            <Typography.Text style={{ color: "white" }}>
              View and update your account details, profile and more.
            </Typography.Text>
            <Card
              style={{
                background: "transparent",
                borderColor: "#787878",
                marginTop: 10,
              }}
              bodyStyle={{
                padding: 15,
                paddingTop: 5,
                borderRadius: "16px",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  fontFamily: "Rubik Mono One",
                  fontSize: 14,
                  marginTop: 10,
                  color: "#F3F3F3",
                }}
              >
                MANAGE USERS
              </div>
              <div
                style={{
                  width: "calc(100% + 30px)",
                  marginLeft: "-15px",
                  borderBottom: "1px solid #FFFFFF1A",
                }}
              ></div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 5,
                  marginBottom: 5,
                  borderBottom: "1px solid #FFFFFF1A",
                  color: "white",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingTop: 16,
                    paddingBottom: 10,
                    fontWeight: "bolder",
                  }}
                >
                  <div style={{ width: "25%" }}>Full Name</div>
                  <div style={{ width: "25%" }}>Username</div>
                  <div style={{ width: "25%" }}>Email</div>
                  <div style={{ width: "25%" }}></div>
                </div>
              </div>
              {allUsers
                .sort((a, b) => a.createdAt - b.createdAt)
                .map((user) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: 5,
                      marginBottom: 5,
                      borderBottom: "1px solid #FFFFFF1A",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingTop: 16,
                        paddingBottom: 10,
                        fontWeight: "bolder",
                      }}
                    >
                      <div style={{ width: "25%" }}>{user.fullName}</div>
                      <div style={{ width: "25%" }}>{user.username}</div>
                      <div style={{ width: "25%" }}>{user.email}</div>
                      <div style={{ width: "25%" }}>
                        {(user as any).permissions}
                      </div>
                    </div>
                  </div>
                ))}
              <Row style={{ width: "100%" }} justify={"end"} align={"middle"}>
                <Button
                  shape="round"
                  style={{
                    marginTop: 15,
                    width: "134px",
                    height: "45px",
                    color: "1E1E1E",
                  }}
                  onClick={() => setCreateUserModal(true)}
                >
                  <b>Add User</b>
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      {createUserModal && <CreateUserModal />}
    </>
  );
};
