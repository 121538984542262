import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useState } from "react";
import Logo from "../images/logo.png";
import { Button, Input, Row, Space, message } from "antd";
import { FiArrowLeftCircle } from "react-icons/fi";

const SignUpComponent = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const executeSignIn = async (event: React.MouseEvent) => {
    event.preventDefault();
    const result = await auth.signUp(
      username,
      password,
      email,
      fullname,
      companyName
    );
    if (result.success) {
      message.success("successfully signed in!");
      navigate({ pathname: "/" });
    } else {
      message.error("Sign Up failed, please try again.");
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);

    if (value.trim() === "") {
      setUsernameError("Username is required");
    } else {
      setUsernameError("");
    }
  };

  const handleFullnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFullname(value);

    if (value.trim() === "") {
      setFullnameError("full name is required");
    } else {
      setFullnameError("");
    }
  };

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCompanyName(value);

    if (value.trim() === "") {
      setCompanyNameError("company name is required");
    } else {
      setCompanyNameError("");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value.trim() === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (value.trim() === "") {
      setEmailError("Email is required");
    } else if (!/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <Row
        style={{ width: "100%", height: "100vh" }}
        justify={"center"}
        align={"middle"}
      >
        <Button
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            margin: "22px",
            padding: 0,
            width: "94px",
            overflow: "visible",
            color: "#999999",
            fontSize: "18px",
          }}
          type="text"
          onClick={() => navigate(-1)}
        >
          <Row align={"middle"} justify={"space-evenly"}>
            <FiArrowLeftCircle size={"44px"} /> Back
          </Row>
        </Button>
        <Row style={{ width: "324px" }} justify={"center"}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              align={"middle"}
              justify={"center"}
              style={{ marginBottom: "30px" }}
            >
              <img src={Logo} style={{ width: "150px" }} alt="logo" />
            </Row>
            <Input
              placeholder="Full Name"
              style={{ textAlign: "center" }}
              className={`text-center border ${fullnameError ? "border-lifecache-color-red" : ""} ${
                fullnameError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={fullname}
              onChange={handleFullnameChange}
            />
            {fullnameError && (
              <span className="text-lifecache-color-red text-sm">{fullnameError}</span>
            )}

            <Input
              placeholder="Company Name"
              style={{ textAlign: "center" }}
              className={`text-center border ${companyNameError ? "border-lifecache-color-red" : ""} ${
                companyNameError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={companyName}
              onChange={handleCompanyNameChange}
            />
            {companyNameError && (
              <span className="text-lifecache-color-red text-sm">{companyNameError}</span>
            )}

            <Input
              placeholder="Username"
              style={{ textAlign: "center" }}
              className={`text-center border ${usernameError ? "border-lifecache-color-red" : ""} ${
                usernameError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={username}
              onChange={handleUsernameChange}
            />
            {usernameError && (
              <span className="text-lifecache-color-red text-sm">{usernameError}</span>
            )}

            <Input
              placeholder="Email"
              style={{ textAlign: "center" }}
              className={`text-center border ${emailError ? "border-lifecache-color-red" : ""} ${
                emailError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={email}
              type="email"
              onChange={handleEmailChange}
            />
            {emailError && (
              <span className="text-lifecache-color-red text-sm">{emailError}</span>
            )}

            <Input
              placeholder="Password"
              style={{ textAlign: "center" }}
              className={`text-center border ${passwordError ? "border-lifecache-color-red" : ""} ${
                passwordError ? "bg-lifecache-color-light-pink" : ""
              }`}
              value={password}
              type="password"
              onChange={handlePasswordChange}
            />
            {passwordError && (
              <span className="text-lifecache-color-red text-sm">{passwordError}</span>
            )}

          </Space>
          <Button
            shape="round"
            style={{ marginTop: 30, width: "134px" }}
            onClick={executeSignIn}
            disabled={!fullname || !companyName || !username || !email || !password
              || fullnameError !== "" || companyNameError !== "" || usernameError !== "" || emailError !== "" || passwordError !== ""}
          >
            <b>Sign Up</b>
          </Button>
        </Row>
      </Row>
    </>
  );
};

export const SignUp = SignUpComponent;
