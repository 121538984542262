import React, { useContext, useEffect, useState } from "react";
import { useMomentsService } from "../services/momentsService";
import IMomentResponse from "../types/moment.res";
import { MomentGrid } from "./MomentGrid";
import { NoSearchResult } from "./SearchResult";
import MomentSort, { SortOption } from "./MomentSort";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useSearchService } from "../services/searchService";
import { SelectMoments } from "./SelectMoments";
import { MomentsContext } from "./MomentsProvider";
import { TotalInsights } from "./TotalInsights";

const MyMoments = ({
  playVideo,
}: {
  playVideo: (url: string, poster: string, moment: IMomentResponse) => void;
}) => {
  const { getAllMomentsSorted, removeMoments } = useMomentsService();
  const { searchMoments } = useSearchService();

  const [moments, setMoments] = useState<IMomentResponse[] | []>([]);
  const [selectedMoments, setSelectedMoments] = useState<string[]>([]);
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [defaultSort, setDefaultSort] = useState<SortOption>(SortOption.Latest);

  let searchTimer: NodeJS.Timeout | null = null;

  const { triggerRefresh, refresh, resetRefresh } = useContext(MomentsContext);

  useEffect(() => {
    if (refresh) {
      refreshMyMoments();

      resetRefresh();
    }
  }, [refresh, resetRefresh]);

  useEffect(() => {
    const fetchLatestMoments = async () => {
      await fetchSortedMoments(SortOption.Latest);
    };

    fetchLatestMoments();
  }, []);

  const onSortOptionChange = async (selectedSortOption: SortOption) => {
    setDefaultSort(selectedSortOption);
    await fetchSortedMoments(selectedSortOption);
  };

  const onSearch = async (query: string) => {
    try {
      const searchResponse = await searchMoments(query, []);
      setMoments(searchResponse.moments);
    } catch (error) {
      console.error("Error searching moments:", error);
    }
  };

  const refreshMyMoments = async () => {
    setSelectedMoments([]);
    setIsSelectedMode(false);
    setDefaultSort(SortOption.Latest);
    await fetchSortedMoments(SortOption.Latest);
  };

  const handleClearSearch = async () => {
    setSearchQuery("");
    setDefaultSort(SortOption.Latest);
    await fetchSortedMoments(SortOption.Latest);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);

    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    searchTimer = setTimeout(() => {
      if (value === "") {
        handleClearSearch();
        return;
      }

      onSearch(value);
    }, 500);
  };

  const fetchSortedMoments = async (sortBy: SortOption) => {
    try {
      const response = await getAllMomentsSorted({ sortBy });
      setMoments(response.moments);
    } catch (error) {
      console.error("Error fetching sorted moments:", error);
    }
  };

  // Select Moments
  const toggleMomentSelection = (momentId: string) => {
    setSelectedMoments((prevSelected) => {
      if (prevSelected.includes(momentId)) {
        return prevSelected.filter((id) => id !== momentId);
      } else {
        return [...prevSelected, momentId];
      }
    });
  };

  const handleSelectedModeToggle = () => {
    setIsSelectedMode(!isSelectedMode);
  };

  const handleDeleteSelectedMoments = async () => {
    await removeSelectedMoments(selectedMoments);
    await refreshMyMoments();
    triggerRefresh();
  };

  const handleCancelSelection = () => {
    setSelectedMoments([]);
    setIsSelectedMode(false);
  };

  const removeSelectedMoments = async (selectedMoments: string[]) => {
    try {
      const res = await removeMoments({ selectedMoments });
    } catch (error) {
      console.error("Error removing selected moments:", error);
    }
  };

  return (
    <>
      <div
        style={{
          width: "90vw",
          textAlign: "left",
          marginBottom: 10,
          marginTop: 20,
        }}
      >
        <TotalInsights moments={moments} />
      </div>
      <div
        style={{
          marginBottom: -10,
          width: "90%",
          textAlign: "left",
          fontFamily: "Rubik Mono One",
          fontSize: 20,
        }}
      >
        <div className="w-full flex flex-row place-content-between items-center">
          <div className="flex flex-row">
            <h3>MY MOMENTS</h3>

            <div className="backdrop-blur-sm backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(4px)_brightness(100%)] flex justify-between items-center">
              <div className="relative ml-6">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-[390px] h-[44px] bg-[#00000066] rounded-[24px] text-white border-none outline-none pl-[40px] pr-[40px]"
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                  <SearchOutlined />
                </div>

                {searchQuery.length > 0 && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <CloseOutlined
                      className="text-white/40"
                      onClick={handleClearSearch}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <SelectMoments
              onSelectedMode={handleSelectedModeToggle}
              onDelete={handleDeleteSelectedMoments}
              onCancel={handleCancelSelection}
              exitSelectedMode={isSelectedMode}
            />

            <MomentSort
              defaultSortOption={defaultSort}
              onSortOptionChanged={onSortOptionChange}
            />
          </div>
        </div>
      </div>

      <div className="pt-4">
        {moments.length > 0 ? (
          <MomentGrid
            moments={moments}
            playVideo={playVideo}
            selectedMoments={selectedMoments}
            toggleSelection={toggleMomentSelection}
            isSelectedMode={isSelectedMode}
          />
        ) : (
          <NoSearchResult />
        )}
      </div>
    </>
  );
};

export default MyMoments;
