import React from "react";
import {MomentsProvider} from "../components/MomentsProvider";
import {Home} from "./Home";

export const Main = () => {
    return (
      <MomentsProvider>
        <Home />
      </MomentsProvider>
    )
}