import { Button, Col, Input, List, Radio, Row, Select, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import IMomentResponse from "../types/moment.res";
import { ControlBar, Player } from "video-react";
import { TagsDisplay } from "./TagsDisplay";
import { useMomentsService } from "../services/momentsService";
import { MomentsContext } from "./MomentsProvider";
import { FaLink } from "react-icons/fa";
import useForwardGeocoding from "../hooks/useForwardGeocoding";
import Place from "../types/place";
import { MapViewSimple } from "./MapViewSimple";

const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

interface EditMomentProps {
  onClose: () => void;
  moment: IMomentResponse | undefined;
}

export const EditMoment = ({ onClose, moment }: EditMomentProps) => {
  const { update, getTagsByMoment } = useMomentsService();
  const { triggerRefresh } = useContext(MomentsContext);

  const [tags, setTags] = useState<string[]>([]);
  const [updatedTags, setUpdatedTags] = useState<string[]>([]);
  const [callActionButtonText, setCallActionButtonText] = useState<string>(
    moment?.callToActionText || ""
  );
  const [callActionButtonLink, setCallActionButtonLink] = useState<string>(
    moment?.callToActionLink || "https://"
  );

  const [[lat, lng], setLatLng] = useState<[number | null, number | null]>([
    Number(moment?.latitude) || null,
    Number(moment?.longitude) || null,
  ]);

  const [tempData, setTempData] = useState({
    title: "",
    description: "",
  });
  const [selectedPlace, setSelectedPlace] = useState<Place>({
    place_name: "",
    center: [lng || 0, lat || 0],
  });

  const [titleError, setTitleError] = useState("");
  const momentsService = useMomentsService();

  const [locationType, setLocationType] = useState<number>(
    moment?.locationId ? 1 : 2
  );
  const [selectedLocationId, setSelectedLocationId] = useState<string>(moment?.locationId || "");
  const [orgLocationIds, setOrgLocationIds] = useState<
    { id: string; locationName: string }[] | undefined
  >(undefined);

  useEffect(() => {
    fetchLocationIds();
  }, []);

  const fetchLocationIds = async () => {
    setOrgLocationIds((await momentsService.getAllLocationIds()).locations);
  };

  useEffect(() => {
    const getTags = async () => {
      if (moment) {
        await fetchTagsForMoment(moment.id);
        setTempData({
          title: moment.name,
          description: moment.description,
        });
      }
    };

    getTags();
  }, [moment]);

  const fetchTagsForMoment = async (momentId: string) => {
    try {
      const response = await getTagsByMoment(momentId);
      setTags(response);
    } catch (error) {
      console.error("Error fetching tags for moment:", error);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTempData((prevTempData) => ({
      ...prevTempData,
      title: value,
    }));

    setTitleError(value.trim() === "" ? "Title is required" : "");
  };

  const descriptionTextareaRef = useRef<HTMLTextAreaElement>(null);

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setTempData((prevTempData) => ({
      ...prevTempData,
      description: value,
    }));
    adjustDescriptionTextareaHeight();
  };

  const adjustDescriptionTextareaHeight = () => {
    if (descriptionTextareaRef.current) {
      descriptionTextareaRef.current.style.height = "auto";
      descriptionTextareaRef.current.style.height =
        descriptionTextareaRef.current.scrollHeight + "px";
    }
  };

  const handleTagsChange = (updatedTags: string[]) => {
    setUpdatedTags(updatedTags);
  };

  const handleUpdateMoment = async () => {
    if (tempData.title.trim() === "") {
      setTitleError("Title is required");
      return;
    }
    if (tempData.description.trim() === "") {
      setTitleError("Description is required");
      return;
    }
    if (moment) {
      await update({
        moment: {
          id: moment.id,
          name: tempData.title,
          description: tempData.description,
          callToActionLink: callActionButtonLink === "https://" ? "" : callActionButtonLink,
          callToActionText: callActionButtonText,
          ...(locationType === 2
            ? {
                latitude: lat?.toString(),
                longitude: lng?.toString(),
                locationId: "",
              }
            : { locationId: selectedLocationId, latitude: "", longitude: "" }),
        },
        tags: updatedTags,
      });
    }

    resetTempData();
    triggerRefresh();
    onClose();
  };

  const resetTempData = () => {
    setTempData({
      title: "",
      description: "",
    });
  };

  const handleCancel = () => {
    resetTempData();
    onClose();
  };

  const address = useForwardGeocoding({
    initialValue: "",
  });

  const handleSelectedPlace = (place: Place) => {
    setSelectedPlace(place);
  };

  return (
    <>
      <style>
        {`
                  .scrollable-content::-webkit-scrollbar {
                    display: none;
                  }
                  .scrollable-content {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                  }
            `}
      </style>
      <div className="z-40 fixed top-10 left-1/2 w-1/2 transform -translate-x-1/2 bg-lifecache-color-black rounded-2xl shadow-lg">
        <div className="bg-lifecache-color-white/20 p-8 rounded-2xl shadow-lg">
          <div className="flex justify-end">
            <Button
              className="bg-lifecache-color-white/20 text-black"
              style={{ border: "inherit" }}
              shape="circle"
              size="small"
              onClick={onClose}
              icon={<CloseOutlined />}
            />
          </div>

          <div className="scrollable-content overflow-y-auto max-h-[calc(100vh-10rem)]">
            <div
              className="top-1/2 left-1/2 w-full transform mt-4"
              style={{
                borderRadius: "16px",
                overflow: "hidden",
                width: "100%",
                height: 462.5,
              }}
            >
              {/* @ts-ignore */}
              <Player playsInline poster={moment.thumbnail_url} src={moment.url} fluid={false} width={"100%"} height={"100%"}>
                <ControlBar autoHide={true}></ControlBar>
              </Player>
              <div
                style={{
                  top: 0,
                  right: 0,
                  position: "absolute",
                  margin: "14px 12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "200px",
                }}
              ></div>
              <div
                style={{
                  bottom: 0,
                  position: "absolute",
                  height: "74px",
                  background: "#00000033",
                  width: "100%",
                  padding: "9px 16px 30px 16px",
                  zIndex: 0,
                }}
              >
                <Typography.Title
                  level={5}
                  style={{ color: "white", margin: 0 }}
                >
                  {moment?.name}
                </Typography.Title>
                <Typography.Text style={{ color: "white", margin: 0 }}>
                  {moment?.description}
                </Typography.Text>
              </div>
            </div>

            <h4>Edit Moment Details</h4>

            <Row>
              <Col span={12}>
                <div className="flex flex-col mb-2">
                  <label className="text-sm font-medium">Moment Title</label>
                  <input
                    type="text"
                    placeholder="Title"
                    value={tempData.title}
                    onChange={handleTitleChange}
                    className={`w-auto h-[24px] 
                                bg-transparent text-white 
                                font-lifecache-brand
						                    outline-none border-r-0 border-l-0 border-t-0  border-b-1 
						                    ${
                                  titleError
                                    ? "border-b-lifecache-color-red"
                                    : "border-b-lifecache-color-white/20"
                                }`}
                  />
                  {titleError && (
                    <span className="text-lifecache-color-red text-sm">
                      {titleError}
                    </span>
                  )}
                </div>

                <div className="flex flex-col mb-2">
                  <label className="text-sm font-medium">
                    Moment Description
                  </label>
                  <textarea
                    ref={descriptionTextareaRef}
                    name="description"
                    rows={3}
                    maxLength={500}
                    inputMode={"text"}
                    placeholder="Description text about moment."
                    value={tempData.description}
                    onChange={handleDescriptionChange}
                    className="bg-transparent text-white
                                font-lifecache-brand resize-none
                                outline-none border-t-0 border-l-0
                                border-r-0 border-b-
                                1 border-b-lifecache-color-white/20 overflow-hidden"
                    style={{
                      minHeight: "72px",
                      maxHeight: "200px",
                    }}
                  />
                  <div className="text-right text-lifecache-color-white/20 mt-2">
                    {tempData.description.length} / 500
                  </div>
                </div>

                <Row
                  justify={"center"}
                  align={"middle"}
                  className="flex flex-col mb-2"
                >
                  <div style={{ width: "100%", color: "white" }}>
                    <Typography.Text
                      style={{ color: "white", fontSize: 14, fontWeight: 5 }}
                    >
                      Call To Action Button(Optional)
                    </Typography.Text>
                  </div>
                </Row>

                <Row justify={"center"} align={"middle"}>
                  <input
                    type="text"
                    value={callActionButtonText}
                    onChange={(e) => setCallActionButtonText(e.target.value)}
                    style={{ width: "100%" }}
                    className="h-[44px]
        bg-[#00000066] rounded-[24px]
        text-white border-none
        outline-none pl-[24px] pr-[24px]
        backdrop-blur-sm backdrop-brightness-[100%]
        [-webkit-backdrop-filter:blur(4px)_brightness(100%)"
                  />
                </Row>

                <Row justify={"center"} align={"middle"} className="mt-1 mb-1">
                  <div style={{ width: "100%", color: "white" }}>
                    <Typography.Text
                      style={{ color: "white", fontSize: 14, fontWeight: 5 }}
                    >
                      Links to
                    </Typography.Text>
                  </div>
                </Row>

                <Row justify={"center"} align={"middle"}>
                  <input
                    type="text"
                    value={callActionButtonLink}
                    onChange={(e) => {
                      if (e.target.value.length < "https://".length) return;
                      setCallActionButtonLink(
                        "https://" +
                          (callActionButtonLink != e.target.value
                            ? e.target.value.split("https://").pop()
                            : "")
                      );
                    }}
                    style={{ width: "100%" }}
                    className="h-[44px]
        bg-[#00000066] rounded-[24px]
        text-white border-none
        outline-none pl-[24px] pr-[24px]
        backdrop-blur-sm backdrop-brightness-[100%]
        [-webkit-backdrop-filter:blur(4px)_brightness(100%)"
                  />
                  <FaLink
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </Row>
                {!URL_REGEX.test(callActionButtonLink) &&
            callActionButtonLink != "https://" && (
              <Row justify={"center"} align={"middle"} style={{ color: "red" }}>
                Please enter a valid url
              </Row>
            )}
              </Col>
              <Col span={12}>
                <TagsDisplay
                  initialTags={tags}
                  onTagsChange={handleTagsChange}
                />
                <Row
                  justify={"start"}
                  align={"middle"}
                  style={{ width: "324px", fontWeight: "bold" }}
                  className="mb-2 ml-5"
                >
                  <div>Location</div>
                </Row>
                <Radio.Group
                  value={locationType}
                  onChange={(e) => setLocationType(e.target.value)}
                  className="ml-5"
                >
                  <Radio value={1} style={{ color: "white" }}>
                    Preassigned Location
                  </Radio>
                  <Radio value={2} style={{ color: "white" }}>
                    Geo-Anchor
                  </Radio>
                </Radio.Group>

                {locationType === 1 && (
                  <Row
                    justify={"start"}
                    align={"middle"}
                    style={{ width: "324px", fontWeight: "bold" }}
                    className="mt-2 mb-2 ml-5"
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Choose a Location"
                      optionFilterProp="children"
                      onChange={(v: string) => setSelectedLocationId(v)}
                      value={selectedLocationId}
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={orgLocationIds?.map(({ id, locationName }) => ({
                        label: locationName,
                        value: id,
                      }))}
                      loading={orgLocationIds === undefined}
                    />
                  </Row>
                )}

                {locationType === 2 && (
                  <>
                    <div className="z-50 mt-5 ml-5">
                      <Input
                        placeholder="Add a Location"
                        style={{ textAlign: "center", width: "324px" }}
                        {...address}
                      />
                      {address.suggestions?.length > 0 && (
                        <div
                          style={{ width: "324px" }}
                          className="absolute bg-white mt-2 rounded-b-lg"
                        >
                          <List
                            className="p-2"
                            dataSource={address.suggestions}
                            renderItem={(suggestion) => (
                              <List.Item
                                className="cursor-pointer"
                                onClick={() => {
                                  address.setValue(suggestion.place_name);
                                  address.setSuggestions([]);
                                  handleSelectedPlace(suggestion);
                                }}
                              >
                                {suggestion.place_name}
                              </List.Item>
                            )}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        width: "85%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="ml-5"
                    >
                      <MapViewSimple
                        selectedPlace={selectedPlace}
                        setLatLng={setLatLng}
                        lat={lat}
                        lng={lng}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <div className="p-4 flex flex-row justify-end">
              <Button
                onClick={handleCancel}
                className="w-[134px] h-[45px] rounded-[44px] text-[18px] bg-lifecache-color-white/20 border-none"
              >
                Cancel
              </Button>

              <Button
                onClick={handleUpdateMoment}
                className="w-[134px] h-[45px] rounded-[44px] text-[18px] border-none ml-2"
                disabled={
                  !tempData.title ||
                  titleError !== "" ||
                  (locationType === 2 && (!lat || !lng)) ||
                  (locationType === 1 && !selectedLocationId) ||
                  !URL_REGEX.test(callActionButtonLink)
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
