import React, {useEffect, useState} from "react";
import {ReactComponent as TrashIcon} from "../assets/svg/trash-icon.svg";
import {ConfirmDialog} from "./ConfirmDialog";

type SelectMomentsProps = {
  onSelectedMode: () => void;
  onDelete: () => void;
  onCancel: () => void;
  exitSelectedMode: boolean;
}

export const SelectMoments = ({onSelectedMode, onDelete, onCancel, exitSelectedMode}: SelectMomentsProps) => {
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setIsSelectedMode(exitSelectedMode);
  }, [exitSelectedMode]);

  const handleSelectedMode = () => {
    setIsSelectedMode(!isSelectedMode);
    onSelectedMode();
  };

  const handleOnDelete = () => {
    setShowConfirmation(true);
  };

  const handleOnCancel = () => {
    setIsSelectedMode(!isSelectedMode);
    onCancel();
  };

  const confirmDelete = () => {
    onDelete();
    setShowConfirmation(false);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <div className="pr-4 font-lifecache-body text-sm text-lifecache-color-white/50">
        {isSelectedMode ?
          <div>
            <div
              className="inline-flex items-center justify-center"
            >
              <div className="hover:cursor-pointer"
                   onClick={handleOnDelete}
              >
                <TrashIcon className="w-6 h-6"/>
              </div>

              <div
                className="bg-lifecache-color-gray hover:cursor-pointer w-fit text-lifecache-color-white/50 inline-flex items-center justify-center text-center rounded-[24px] border border-solid m-1 h-[24px] gap-[10px] px-[16px] py-[4px]"
                onClick={handleOnCancel}
              >
                Cancel
              </div>
            </div>
          </div>
          :
          <div className="hover:cursor-pointer" onClick={handleSelectedMode}>
            Select
          </div>
        }

        <ConfirmDialog
          isVisible={showConfirmation}
          title="Confirm deletion"
          description="Are you sure you want to delete the selected moments?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete} />

      </div>
    </>
  );
}
