import { useEffect, useState } from "react";
import IMomentResponse from "../types/moment.res";
import { useSearchService } from "../services/searchService";
import { MomentGrid } from "./MomentGrid";

export const SearchResult = ({
  queryText,
  queryTags,
  onSearchResults,
  playVideo,
}: {
  queryText: string;
  queryTags: string[];
  onSearchResults: (moments: IMomentResponse[]) => void;
  playVideo: (url: string, poster: string, moment: IMomentResponse) => void;
}) => {
  const { searchMoments } = useSearchService();

  const [moments, setMoments] = useState<IMomentResponse[] | []>([]);

  useEffect(() => {
    const fetchSearchMoments = async () => {
      try {
        const searchResponse = await searchMoments(queryText, queryTags);
        setMoments(searchResponse.moments);
        onSearchResults(searchResponse.moments);
      } catch (error) {
        throw error;
      }
    };

    fetchSearchMoments();
  }, [queryText, queryTags]);

  return (
    <>
      <div
        style={{
          width: "90vw",
          textAlign: "left",
          marginBottom: -10,
          fontFamily: "Rubik Mono One",
          fontSize: 20,
        }}
      >
        <h3>MY MOMENTS</h3>
      </div>

      {moments.length > 0 ? (
        <MomentGrid moments={moments} playVideo={playVideo} />
      ) : (
        <NoSearchResult />
      )}
    </>
  );
};

export const NoSearchResult = () => {
  return (
    <>
      <div
        className="momentScroll"
        style={{
          display: "flex",
          overflowX: "auto",
          width: "90vw",
          flexWrap: "wrap",
          flexDirection: "column",
          borderRadius: "16px",
          alignContent: "flex-start",
        }}
      >
        {"No results found."}
      </div>
    </>
  );
};
