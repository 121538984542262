import logo from "../assets/svg/lifecahe_logo.svg"

type LogoProps = {
    isVertical?: boolean;
}

export const LifecacheLogo = ({ isVertical = true }: LogoProps) => {
    const flexDirection = isVertical ? 'flex-col' : 'flex-row';

    return (
        <>
            <div className= {`flex ${flexDirection} items-center p-4 text-white text-3xl gap-3 font-lifecache-brand font-semibold`}>
                <img src={logo} alt="Lifecache Logo" />
                lifecache
            </div>
        </>
    );
}
