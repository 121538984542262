import React, {useState} from 'react';

type TagInputProps = {
  onAddTag: (tag: string) => void;
};

export const TagInput: React.FC<TagInputProps> = ({ onAddTag }) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      onAddTag(inputValue.trim());
      setInputValue('');
    }
  };

  return (
    <div className="flex flex-row ">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        className="w-full h-[44px]
        bg-[#00000066] rounded-[24px]
        text-white border-none
        outline-none pl-[24px] pr-[24px]
        backdrop-blur-sm backdrop-brightness-[100%]
        [-webkit-backdrop-filter:blur(4px)_brightness(100%)"
        placeholder="Add a tag"
      />
    </div>
  );
};
