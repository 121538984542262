import { useAuth } from "./useAuth";
import { ComponentType, FC } from "react";
import { NoUser } from "../pages/NoUser";
import { Spin } from "antd";

const withAuthentication =
  <P extends any>(Component: ComponentType<P>): FC<P> =>
  (props: any) => {
    const { isAuthenticated, isLoading } = useAuth();
    if (isLoading) {
      return <Spin spinning={true} />;
    }
    return isAuthenticated ? <Component {...props} /> : <NoUser />;
  };

export default withAuthentication;
