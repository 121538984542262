import { useNavigate } from "react-router-dom";
import { Button, Row, Space, Typography } from "antd";
import Logo from "../images/logo.png";

export const NoUser = () => {
  const navigate = useNavigate();

  return (
    <Row
      style={{ width: "100vw", height: "100vh" }}
      justify="center"
      align="middle"
    >
      <Row style={{ width: "318px" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row
            align={"middle"}
            justify={"center"}
            style={{ marginBottom: "30px" }}
          >
            <img src={Logo} style={{ width: "150px" }} alt="logo" />
          </Row>
          <Row align={"middle"} justify={"center"}>
            <div
              style={{
                fontSize: "44px",
                width: "200px",
                textAlign: "center",
                fontWeight: 400,
                fontFamily: "Rubik Mono One",
                lineHeight: "54px",
              }}
            >
              THE WORLD IS YOUR FEED
            </div>
          </Row>

          <Row justify={"center"} style={{ marginTop: "54px" }}>
            <Typography.Text style={{ color: "white" }}>
              A Location Based AR Experience
            </Typography.Text>
          </Row>
          <Row justify={"center"} style={{ marginTop: "30px" }}>
            <Button
              shape="round"
              style={{ marginTop: 15, width: "134px", height: "45px" }}
              onClick={() => {
                navigate({ pathname: "/signin" });
              }}
            >
              <b>Login</b>
            </Button>
            {/* <Button
              shape="round"
              style={{ marginTop: 15, width: "134px", height: "45px" }}
              onClick={() => {
                navigate({ pathname: "/signup" });
              }}
            >
              <b>Sign Up</b>
            </Button> */}
          </Row>
        </Space>
      </Row>
    </Row>
  );
};
