import IUserRequest from "../types/user.req";
import IUserResponse from "../types/user.res";
import { post } from "./backend";
import IUserProfileRequest from "../types/user.profile.req";

interface UserService {
  getUser(username: String): Promise<IUserResponse>;
  create(data: IUserRequest): Promise<IUserResponse>;
  update(data: IUserRequest): Promise<IUserResponse>;
  updateProfile(data: IUserProfileRequest): Promise<IUserResponse>;
  createForOrg(
    fullName: string,
    username: string,
    email: string
  ): Promise<IUserResponse>;
  getAllUsersOrg(): Promise<IUserResponse[]>;
}

export const ENDPOINTS = {
  getUser: post(`users/get`),
  getAllUsersOrg: post(`users/getAllUsersOrg`),
  createUser: post(`users/create`),
  updateUser: post(`users/update`),
  createOrgUser: post(`users/createForOrg`),
};

export const UserProvideService = (): UserService => {
  const getUser = async (username: String): Promise<IUserResponse> => {
    const data = {
      username: username,
    };

    try {
      const response = ENDPOINTS.getUser(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getAllUsersOrg = async (): Promise<IUserResponse[]> => {
    try {
      const response = ENDPOINTS.getAllUsersOrg({});
      return response.then(d => d.users);
    } catch (error) {
      throw error;
    }
  };

  const create = async (data: IUserRequest): Promise<IUserResponse> => {
    try {
      const response = ENDPOINTS.createUser(data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const createForOrg = async (
    fullName: string,
    username: string,
    email: string
  ): Promise<IUserResponse> => {
    try {
      const response = ENDPOINTS.createOrgUser({ fullName, username, email });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const update = async (data: IUserRequest): Promise<IUserResponse> => {
    try {
      const response = ENDPOINTS.updateUser(data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateProfile = async (
    data: IUserProfileRequest
  ): Promise<IUserResponse> => {
    try {
      const response = ENDPOINTS.updateUser(data);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    getUser,
    create,
    update,
    updateProfile,
    createForOrg,
    getAllUsersOrg,
  };
};
