/* eslint-disable import/no-webpack-loader-syntax */
import Map, { MapRef, Marker, NavigationControl } from "react-map-gl";
import { useGeolocated } from "react-geolocated";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CurrentLocationMarker } from "./CurrentLocationMarker";
import { useMomentsService } from "../services/momentsService";
import { IMomentRequest } from "../types/moment.req";
import IMomentResponse from "../types/moment.res";
import { LocationMarker } from "./LocationMarker";
import { v4 as uuidv4 } from "uuid";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { debounce } from "lodash";
import useSupercluster from "use-supercluster";
import { Avatar, Card, Divider } from "antd";
import { ReactComponent as TempCitiea } from "../images/temp_cities.svg";
import { ReactComponent as TempGender } from "../images/temp_gender.svg";

import "./mapview.css";

import * as turf from "@turf/turf";
import { ThemeContext } from "../contexts/ThemeContext";
import { useAuth } from "../hooks/useAuth";
import { SearchBar } from "./SearchBar";
import { MomentsContext } from "./MomentsProvider";

// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const MapView = ({ playVideo }: any) => {
  const { theme } = useContext(ThemeContext);
  const momentsService = useMomentsService();
  const { currentUser } = useAuth();
  const [moments, setMoments] = useState<IMomentResponse[] | []>([]);
  const [viewPortChanged, setViewportChanged] = useState<boolean>(false);
  const [viewState, setViewState] = useState({
    latitude: 25.7711,
    longitude: -80.1894,
    zoom: 14,
  });
  const timeDelayMilliseconds = 500;
  const throttledLoadData = useCallback(
    debounce((latitude: number, longitude: number) => {
      fetchData({ latitude: latitude, longitude: longitude });
    }, timeDelayMilliseconds),
    []
  );

  // Search Moments
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchTags, setSearchTags] = useState<string[]>([]);

  const { refresh, resetRefresh } = useContext(MomentsContext);

  useEffect(() => {
    if (refresh) {
      throttledLoadData(viewState.latitude, viewState.longitude);
      resetRefresh();
    }
  }, [refresh, resetRefresh]);

  const handleSearch = (query: string) => {
    if (query === "" && searchTags.length === 0) {
      setSearchText("");
      setSearchMode(false);
      // refresh
      throttledLoadData(viewState.latitude, viewState.longitude);
      return;
    }

    setSearchText(query);
    setSearchMode(true);
  };

  const handleTagsSearch = (tags: string[]) => {
    if (tags.length === 0 && searchText === "") {
      setSearchTags([]);
      setSearchMode(false);
      // refresh
      throttledLoadData(viewState.latitude, viewState.longitude);
      return;
    }

    setSearchTags(tags);
    setSearchMode(true);
  };

  const handleSearchResults = (momentsSearchResult: IMomentResponse[]) => {
    setMoments(momentsSearchResult);
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  const mapRef = useRef<MapRef>();

  const fetchData = useCallback(
    async ({
      latitude,
      longitude,
    }: {
      latitude: number;
      longitude: number;
    }) => {
      const map = mapRef.current?.getMap();
      try {
        let distanceInMetres;

        if (map) {
          distanceInMetres = turf.distance(
            [0, map.getBounds().getEast()],
            [0, map.getBounds().getWest()],
            { units: "meters" }
          );
        }
        const momentRequest: IMomentRequest = {
          latitude: latitude,
          longitude: longitude,
          radius: 5000000000,
          device_type: "Web",
          username: currentUser.username,
          orgId: currentUser.orgId,
        };

        const response = await momentsService.getByLocation(momentRequest);

        setMoments([...response.moments].map((m) => ({ ...m, id: uuidv4() })));
      } catch (error) {
        console.error("Error fetching moments:", error);
      }
    },
    []
  );

  useEffect(() => {
    if (!coords) {
      return;
    }

    setViewState({
      latitude: coords.latitude,
      longitude: coords.longitude,
      zoom: 14,
    });

    throttledLoadData(coords.latitude, coords.longitude);
  }, [coords]);

  const points = moments.map((moment) => ({
    type: "Feature",
    properties: {
      cluster: false,
      momentId: moment.id,
      image: moment.thumbnail_url,
      moment,
    },
    geometry: {
      type: "Point",
      coordinates: [moment.longitude, moment.latitude],
    },
  }));

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : undefined;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bounds as any,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const nearbyMoments = moments;

  const renderBubbleAnalytic = (title: string, Image: any) => (
    <div
      style={{
        height: "205px",
        padding: "34px",
        width: "240px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: "#F3F3F3B3",
          fontSize: 14,
        }}
      >
        {title}
      </div>
      <Image
        style={{
          marginTop: 10,
        }}
      />
    </div>
  );

  return (
    <>
      {!isGeolocationAvailable ? (
        <div>Your browser does not support Geolocation.</div>
      ) : !isGeolocationEnabled ? (
        <div>Geolocation is not enabled.</div>
      ) : null}
      {coords ? (
        <>
          <div
            style={{
              background: "#252525",
              width: "100%",
              padding: "20px 0px 20px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="rounded-[34px] overflow-hidden"
              style={{ width: "calc(100% - 80px)" }}
            >
              <SearchBar
                containerStyle={{
                  position: "absolute",
                  zIndex: 1,
                  width: "calc(100% - 80px)",
                }}
                onSearch={handleSearch}
                onTagsSearch={handleTagsSearch}
                onSearchResults={handleSearchResults}
                hideSearch={searchMode}
              />

              <Map
                // @ts-ignore
                ref={mapRef}
                mapLib={mapboxgl}
                renderWorldCopies={false}
                {...viewState}
                mapboxAccessToken="pk.eyJ1Ijoibml0aGlsYW4tbGlmZWNhY2hlIiwiYSI6ImNsaTBqNTFseTBjZnQzc256cWg4NGl1YzYifQ.KutSc5nIKiQJ9cglVW2fCg"
                onMove={(evt) => {
                  setViewState(evt.viewState);
                  if (!searchMode)
                    throttledLoadData(viewState.latitude, viewState.longitude);
                  setViewportChanged(true);
                }}
                style={{ width: "100%", height: "400px" }}
                mapStyle={
                  theme === "dark"
                    ? "mapbox://styles/mapbox/dark-v10"
                    : "mapbox://styles/mapbox/light-v10"
                }
              >
                {coords && (
                  <CurrentLocationMarker
                    latitude={coords.latitude}
                    longitude={coords.longitude}
                  />
                )}

                {clusters.map(
                  (cluster: {
                    geometry: { coordinates: [any, any] };
                    properties: {
                      image?: any;
                      cluster?: any;
                      point_count?: any;
                      momentId: string;
                      moment: IMomentResponse;
                    };
                    id: any;
                  }) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                      cluster: isCluster,
                      point_count: pointCount,
                      image,
                      momentId,
                      moment,
                    } = cluster.properties;

                    if (isCluster || pointCount > 0) {
                      return (
                        <Marker
                          key={`cluster-${cluster.id}`}
                          latitude={latitude}
                          longitude={longitude}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="cluster-marker"
                            style={{
                              width: `${
                                10 + (pointCount / points.length) * 20
                              }px`,
                              height: `${
                                10 + (pointCount / points.length) * 20
                              }px`,
                            }}
                            onClick={() => {
                              const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(
                                  cluster.id
                                ),
                                20
                              );

                              setViewState({
                                ...viewState,
                                latitude,
                                longitude,
                                zoom: expansionZoom,
                              });
                            }}
                          >
                            <Avatar
                              className="bg-gray-600 border-2 border-green-500 "
                              size={50}
                              alt="Location Image"
                            >
                              {pointCount}
                            </Avatar>
                          </div>
                        </Marker>
                      );
                    }
                    return (
                      <LocationMarker
                        playVideo={playVideo}
                        url={moment.url}
                        key={cluster.id}
                        markerKey={cluster.id}
                        latitude={latitude}
                        longitude={longitude}
                        image={image}
                        id={momentId}
                        moment={moment}
                      />
                    );
                  }
                )}

                <NavigationControl />
                {/* {viewPortChanged && (
              <Button
                className="m-2"
                // onClick={() => {
                //   setViewportChanged(false);
                //   throttledLoadData(viewState.latitude, viewState.longitude);
                // }}
              >
                Search this area
              </Button>
            )} */}
              </Map>
            </div>
          </div>
        </>
      ) : (
        <div>Getting the location data&hellip;</div>
      )}
    </>
  );
};
