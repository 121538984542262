import PropTypes from "prop-types";
import React, {useState} from "react";

interface Props {
    text: string;
    onTagClick: (tag: string) => void
}

export const TagChip = ({text = "tag", onTagClick}: Props): JSX.Element => {

    const [isSelected, setIsSelected] = useState(false);

    const handleClick = () => {
        if (onTagClick) {
            onTagClick(text);
        }
        setIsSelected(!isSelected);
    };

    return (
        <>
            <div
                className={`bg-[#00000066] inline-flex m-1 h-[34px] items-center justify-center gap-[10px] px-[16px] py-[4px] relative rounded-[24px] border border-solid ${isSelected ? 'border-lifecache-color-green' : 'border-transparent'}`}
                style={{ cursor: "pointer" }}
                onClick={handleClick}
            >
                <div
                    className={`relative w-fit font-normal text-white text-[14px] text-center tracking-[0] leading-[normal]`}
                >
                    #{text}
                </div>
            </div>
        </>
    );
};

TagChip.propTypes = {
    text: PropTypes.string,
    onTagClick: PropTypes.func,
};
