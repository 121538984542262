import React from 'react';
import {Marker} from "react-map-gl";
import {FaMapMarkerAlt} from "react-icons/fa";

type CurrentLocationMarkerProps = {
    latitude: number;
    longitude: number;
}

export const CurrentLocationMarker = ({latitude, longitude}: CurrentLocationMarkerProps) => {
    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            anchor="bottom">
            <div>
                <FaMapMarkerAlt className="text-red-500 w-10 h-10"/>
            </div>
        </Marker>
    );
}
