import React, {useEffect, useState} from "react";
import {TagChip} from "./TagChip";
import ITagResponse from "../types/tag.res";
import {useSearchService} from "../services/searchService";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactComponent as SearchIcon } from "../assets/svg/search-rounded-light-icon.svg";
import IMomentResponse from "../types/moment.res";

export const SearchBar = ({
                              containerStyle,
                              onSearch,
                              onTagsSearch,
                              onSearchResults,
                              hideSearch }: {
    containerStyle: React.CSSProperties;
    onSearch: (query: string) => void;
    onTagsSearch: (tags: string[]) => void,
    onSearchResults: (moments: IMomentResponse[]) => void;
    hideSearch: boolean }): JSX.Element => {

    const { getAllTags, searchMoments } = useSearchService();

    const [tagsData, setTagsData] = useState<ITagResponse | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedTags, setSelectedTags] = useState(new Set<string>());
    const [isSearchTextFieldOpen, setIsSearchTextFieldOpen] = useState(false);

    let searchTimer: NodeJS.Timeout | null = null;

    useEffect(() => {
        const fetchSearchMoments = async () => {
            try {
                const searchResponse = await searchMoments(searchQuery, Array.from(selectedTags.values()));
                // setMoments(searchResponse.moments);
                onSearchResults(searchResponse.moments);
            } catch (error) {
                throw error;
            }
        };

        fetchSearchMoments();
    }, [searchQuery, selectedTags]);

    const handleSearchTextField = () => {
        setIsSearchTextFieldOpen(!isSearchTextFieldOpen)
    };

    const handleClearSearchTextField = () => {
        setSearchQuery("");
        onSearch("");
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchQuery(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        searchTimer = setTimeout(() => {
            onSearch(value);
        }, 500);
    };

    const handleTagClick = (tagText: string) => {
        const newSelectedTags = new Set(selectedTags);

        if (newSelectedTags.has(tagText)) {
            newSelectedTags.delete(tagText);
        } else {
            newSelectedTags.add(tagText);
        }

        setSelectedTags(newSelectedTags);
        onTagsSearch(Array.from(newSelectedTags.values()));
    };

    useEffect(() => {
        if (!hideSearch) {
            setIsSearchTextFieldOpen(false);
        }

    }, [hideSearch]);

    useEffect(() => {
        const fetchTagsData = async () => {
            try {
                const tagsResponse = await getAllTags();
                setTagsData(tagsResponse);
            } catch (error) {
                throw error;
            }
        };

        fetchTagsData();
    }, []);


    return (
        <>
            <div style={containerStyle}>
                <div
                    className="h-[72px] bg-[#00000033] rounded-[34px_34px_0px_0px] backdrop-blur-sm backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(4px)_brightness(100%)] flex justify-between items-center">

                    {!isSearchTextFieldOpen && (
                        <SearchIcon
                            className="ml-6 z-10"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchTextField}
                        />
                    )}

                    {isSearchTextFieldOpen && (
                        <div className="relative ml-6">
                            <input
                                type="text"
                                placeholder="Search"
                                className="w-[293px] h-[44px] bg-[#00000066] rounded-[24px] text-white border-none outline-none pl-[40px] pr-[40px]"
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                                <SearchOutlined />
                            </div>

                            {searchQuery.length > 0 && (
                                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                                    <CloseOutlined
                                        className="text-white/40"
                                        onClick={handleClearSearchTextField}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {/*Tags Section*/}
                    <div className="mr-16">
                        {tagsData?.tags.map((tag, index) => (
                            <TagChip key={index} text={tag} onTagClick={handleTagClick}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
