import { NavBar } from "../components/NavBar";
import withAuthentication from "../hooks/withAuthentication";
import { MapView } from "../components/MapView";
import React, {useContext, useState} from "react";
import IMomentResponse from "../types/moment.res";
import MyMoments from "../components/MyMoments";
import {MomentsContext} from "../components/MomentsProvider";
import {MomentPlayer} from "../components/MomentPlayer";
import {MomentActionTypes} from "../components/MomentActions";
import {ConfirmDialog} from "../components/ConfirmDialog";
import {useMomentsService} from "../services/momentsService";
import {EditMoment} from "../components/EditMoment";
import { MomentInsights } from "../components/MomentInsights";

export const Home = withAuthentication(() => {
  const [openVideo, setOpenVideo] = useState(false);
  const [playingVideo, setPlayingVideo] = useState("");
  const [activeMoment, setActiveMoment] = useState<IMomentResponse>();
  const [poster, setPoster] = useState("");

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditMoment, setShowEditMoment] = useState(false);
  const [showMomentInsights, setShowMomentInsights] = useState(false);
  const { removeMoments } = useMomentsService();
  const { triggerRefresh } = useContext(MomentsContext);

  const playVideo = (url: string, poster: string, moment: IMomentResponse) => {
    if (!openVideo) {
      setPlayingVideo(url);
      setActiveMoment(moment);
      setPoster(poster);
      setOpenVideo(true);
    }
  };

  const handleMomentAction = (action: MomentActionTypes) => {
    if (action === MomentActionTypes.Edit) handleEditMoment();
    else if (action === MomentActionTypes.Insights) handleInsights();
    else if (action === MomentActionTypes.Delete) handleOpenDeleteConfirm();
  }

  const handleEditMoment = () => {
    setOpenVideo(false);
    setShowEditMoment(true);
  }

  const handleInsights = () => {
    setOpenVideo(false);
    setShowMomentInsights(true);
  }

  const handleOpenDeleteConfirm = () => {
    setOpenVideo(false);
    setShowDeleteConfirmation(true);
  }

  const handleCancelDeleteConfirm = () => {
    setShowDeleteConfirmation(false);
  }

  const handleDeleteMoment = async () => {
    setShowDeleteConfirmation(false);
    setOpenVideo(false);

    try {
      if (activeMoment) {
        const res = await removeMoments({selectedMoments: [activeMoment.id]});
      }
    } catch (error) {
      console.error('Error removing selected moments:', error);
    }

    triggerRefresh();
  }

  return (
    <>
      <NavBar/>
      <div
        className="flex flex-col items-center justify-start text-white bg-lifecache-color-gray"
        style={{minHeight: "calc(100vh - 90px)"}}
      >
        <MapView playVideo={playVideo}/>
        <MyMoments playVideo={playVideo}/>
      </div>

      {openVideo && playingVideo !== "" && (
        <MomentPlayer
          setOpenVideo={setOpenVideo}
          poster={poster}
          playingVideo={playingVideo}
          activeMoment={activeMoment}
          onMomentAction={handleMomentAction}
        />
      )}

      {showEditMoment && (
        <EditMoment
          onClose={() => setShowEditMoment(false)}
          moment={activeMoment}
        />
      )}

      {showMomentInsights && (
        <MomentInsights
          onClose={() => setShowMomentInsights(false)}
          moment={activeMoment}
        />
      )}

      <ConfirmDialog
        isVisible={showDeleteConfirmation}
        title="Confirm deletion"
        description="Are you sure you want to delete the selected moments?"
        onConfirm={() => handleDeleteMoment()}
        onCancel={handleCancelDeleteConfirm}/>

    </>
  );
});
