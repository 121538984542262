import { useState, ChangeEvent } from "react";
import Place from "../types/place";

interface UseInputProps {
  initialValue: string;
}

const useForwardGeocoding = ({ initialValue }: UseInputProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const [suggestions, setSuggestions] = useState<Place[]>([]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);

    const accessToken =
      "pk.eyJ1Ijoibml0aGlsYW4tbGlmZWNhY2hlIiwiYSI6ImNsaTBqNTFseTBjZnQzc256cWg4NGl1YzYifQ.KutSc5nIKiQJ9cglVW2fCg";

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${accessToken}&autocomplete=true`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results?.features);
    } catch (error) {
    }
  };

  return {
    value,
    onChange: handleChange,
    setValue,
    suggestions,
    setSuggestions,
  };
};

export default useForwardGeocoding;
