import IMomentResponse from "../types/moment.res";
import { ReactComponent as ProcessingImage } from "../images/processing.svg";
import { ReactComponent as CheckIcon } from "../assets/svg/check-circle-icon.svg";
import { ReactComponent as UncheckIcon } from "../assets/svg/uncheck-circle-icon.svg";

export const MomentItem = ({
                             moment,
                             playVideo,
                             isSelected,
                             toggleSelection,
                             showSelectionIcon = false
                           }: {
  moment: IMomentResponse;
  playVideo: (url: string, poster: string, moment: IMomentResponse) => void;
  isSelected: boolean;
  toggleSelection: (momentId: string) => void;
  showSelectionIcon?: boolean;
}) => {

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (showSelectionIcon) {
      e.stopPropagation();
      toggleSelection(moment.id);
    } else if (moment.status === "READY") {
      playVideo(moment.url, moment.thumbnail_url, moment);
    }
  };

  return (
    <div
      className="relative"
      style={{
        display: "inline-block",
        width: "200px",
        height: "200px",
        borderRadius: "16px",
        overflow: "hidden",
        cursor: "pointer",
        margin: "5px",
      }}
      onClick={handleClick}
    >
      {moment.status === "READY" ? (
        <img
          src={moment.thumbnail_url}
          style={{
            objectFit: "cover",
            width: "200px",
            height: "200px",
          }}
        />
      ) : (
        <ProcessingImage
          style={{
            objectFit: "cover",
            width: "200px",
            height: "200px",
            background: "black",
          }}
        />
      )}
      <div
        style={{
          position: "absolute",
          background:
            "linear-gradient(to bottom, rgba(255,0,0,0), rgba(57, 57, 57, 0.5))",
          inset: 0,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          paddingLeft: "10px",
        }}
      >
        <h3>{moment.name}</h3>
      </div>

      {showSelectionIcon && (
        <a
          className="absolute top-0 left-0 m-2"
          onClick={(e) => {
            e.stopPropagation();
            toggleSelection(moment.id);
          }}
        >
          {isSelected ? <CheckIcon className="w-6 h-6"/> : <UncheckIcon className="w-6 h-6"/>}
        </a>
      )}
    </div>
  );
};
