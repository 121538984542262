import { Auth } from "aws-amplify";
import axios from "axios";
import { AxiosConfig } from "../config/axiosConfig";

export const http = axios.create(AxiosConfig);

export const post = (uri: string) => {
  return async (body: any) => {
    const session = await Auth.currentSession();
    const authToken = session.getAccessToken();
    const response = http.post<any>(uri, body, {
      headers: {
        Authorization: authToken.getJwtToken(),
      },
    });

    return (await response).data;
  };
};

export const get = (uri: string) => {
  return async () => {
    const session = await Auth.currentSession();
    const authToken = session.getAccessToken();
    const response = http.get<any>(uri, {
      headers: {
        Authorization: authToken.getJwtToken(),
      },
    });

    return (await response).data;
  };
};
