import React, { createContext, useState, useCallback, ReactNode } from 'react';

type MomentsContextType = {
  refresh: boolean;
  triggerRefresh: () => void;
  resetRefresh: () => void;
};

export const MomentsContext = createContext<MomentsContextType>({
  refresh: false,
  triggerRefresh: () => {},
  resetRefresh: () => {},
});

type MomentsProviderProps = {
  children: ReactNode;
};

export const MomentsProvider: React.FC<MomentsProviderProps> = ({ children }) => {
  const [refresh, setRefresh] = useState<boolean>(false);

  const triggerRefresh = useCallback(() => {
    setRefresh(true);
  }, []);

  const resetRefresh = useCallback(() => {
    setRefresh(false);
  }, []);

  return (
    <MomentsContext.Provider value={{ refresh: refresh, triggerRefresh: triggerRefresh, resetRefresh: resetRefresh }}>
      {children}
    </MomentsContext.Provider>
  );
};