import { LifecacheLogo } from "./LifecacheLogo";
import {useContext, useState} from "react";
import { Profile } from "./Profile";
import { useAuth } from "../hooks/useAuth";
import {Avatar, message} from "antd";
import { ReactComponent as SettingsIcon } from "../assets/svg/settings-icon.svg";
import { ReactComponent as AddIcon } from "../assets/svg/add-icon.svg";
import { UploadMoment } from "./UploadMoment";
import moment from "moment";
import {useGeolocated} from "react-geolocated";
import useReverseGeocoding from "../hooks/useReverseGeocoding";
import {MomentsContext} from "./MomentsProvider";

export const NavBar = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const { currentUser, updateUserProfile, updateCurrentUser } = useAuth();

  const openAvatarClassName = `p-3 ${
    isProfileOpen ? "bg-lifecache-color-gray rounded-tl-2xl" : ""
  }`;

  const {coords, isGeolocationAvailable, isGeolocationEnabled} =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  const locationName = useReverseGeocoding({latitude: coords?.latitude??0, longitude: coords?.longitude??0});

  const { triggerRefresh } = useContext(MomentsContext);

  const handleProfileClick = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handleUploadClick = () => {
    setIsUploadOpen(!isUploadOpen);
  };

  const handleUploadedMoment = () => {
    setIsUploadOpen(!isUploadOpen);
    triggerRefresh();
  };

  const onUpdateProfile = async (fullName: string, location: string, bio: string) => {
    const result = await updateUserProfile(
      fullName,
      location,
      bio
    );

    if (result.success) {
      updateCurrentUser();

      message.success("Profile updated.");
    } else {
      message.error(
        "Update profile failed."
      );
    }
  };

  return (
    <>
      <header className="bg-black flex items-center justify-between py-1 px-6 text-white">
        <div className="flex items-center">
          <LifecacheLogo isVertical={false} />
        </div>

        <div className="flex items-center">
          {/* <SettingsIcon className="w-14 h-14" /> */}

          <div
            className="flex items-center"
            style={{ cursor: "pointer" }}
            onClick={handleUploadClick}
          >
            <AddIcon className="w-7 h-7" />
            <h4 className="ml-2 text-lifecache-color-green">Add a Moment</h4>
          </div>

          <div className={openAvatarClassName}>
            <Avatar
              className="bg-white cursor-pointer"
              size={50}
              src={currentUser?.profileUrl || ""}
              onClick={handleProfileClick}
            />
          </div>
        </div>

        {isProfileOpen && (
          <Profile
            fullName={currentUser?.fullName}
            profilePic={currentUser?.profileUrl}
            bio={currentUser.description}
            location={locationName??''}
            memberSince={moment(currentUser.createdAt).format("YYYY")}
            onUpdateProfile={onUpdateProfile}
          />
        )}

        {isUploadOpen && <UploadMoment onClose={handleUploadClick} onUploaded={handleUploadedMoment} />}
      </header>
    </>
  );
};
