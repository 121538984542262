import ITagResponse from "../types/tag.res";
import {get, post} from "./backend";
import ISearchResponse from "../types/search.res";

interface SearchService {
    searchMoments(queryText: string, tags: string[]): Promise<ISearchResponse>;
    getAllTags(): Promise<ITagResponse>;
}

export const ENDPOINTS = {
    searchMoments: post(`moments/search`),
    getTags: get('moments/tags/all'),
};

export const useSearchService = (): SearchService => {

    const searchMoments = async (
        queryText: string,
        tags: string[]
    ): Promise<ISearchResponse> => {
        try {
            const response = ENDPOINTS.searchMoments({queryText, tags})
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getAllTags = async (): Promise<ITagResponse> => {
        try {
            const response = ENDPOINTS.getTags();
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        searchMoments,
        getAllTags,
    };
}
