import { post } from "./backend";
import { IMomentCreateRequest, IMomentRequest } from "../types/moment.req";
import IMomentsResponse from "../types/moment.res";
import { IMomentUpdateRequest } from "../types/moment.update.req";
import IMomentResponse from "../types/moment.res";

interface MomentsService {
  getByLocation(data: IMomentRequest): Promise<IMomentsResponse>;
  getAllMomentsSorted(data: { sortBy: string }): Promise<IMomentsResponse>;
  removeMoments(data: { selectedMoments: string[] }): Promise<any>;
  create(data: IMomentCreateRequest): Promise<any>;
  update(data: IMomentUpdateRequest): Promise<any>;
  getTagsByMoment(momentId: string): Promise<string[]>;
  getFavoritesByPreset(
    momentId: string,
    preset: IPreset
  ): Promise<{ label: string; value: number }[]>;
  getViewsByPreset(
    momentId: string,
    preset: IPreset
  ): Promise<{ label: string; value: number }[]>;
  getAllFavoritesByPreset(
    preset: IPreset
  ): Promise<{ [key: string]: { label: string; value: number }[] }>;
  getAllViewsByPreset(
    preset: IPreset
  ): Promise<{ [key: string]: { label: string; value: number }[] }>;
  getOverView(momentId: string): Promise<{
    favorites: { value: number; changeThisWeek: number };
    views: { value: number; changeThisWeek: number };
    avgWatchTime: { value: number; changeThisWeek: number };
  }>;
  getAllMomentsOverView(): Promise<{
    [momentId: string]: {
      favorites: { value: number; changeThisWeek: number };
      views: { value: number; changeThisWeek: number };
      avgWatchTime: { value: number; changeThisWeek: number };
    };
  }>;
  getOverViewRange(range: "month" | "day" | "week" | "year"): Promise<{
    favorites: {
      favorites: number;
      momentId: string;
      avgWatchTime: number;
      views: number;
      date: string;
    }[];
    views: {
      favorites: number;
      momentId: string;
      views: number;
      avgWatchTime: number;
      date: string;
    }[];
    avgWatchTime: {
      favorites: number;
      momentId: string;
      views: number;
      date: string;
      avgWatchTime: number;
    }[];
  }>;
  getAllLocationIds(): Promise<{
    locations: { id: string; locationName: string }[];
  }>;
}

export enum IPreset {
  YTD = "YTD",
  WEEK = "WEEK",
  BIWEEK = "BIWEEK",
  MONTH = "MONTH",
}

export const ENDPOINTS = {
  getByLocation: post(`moments/getByLocation`),
  getAllMomentsSorted: post(`moments/sorted`),
  removeMoments: post(`moments/remove`),
  createMoment: post(`moments/create`),
  updateMoment: post("moments/update"),
  getTagsByMoment: post("moments/tags"),
  getFavoritesByPreset: post("moments/favoritesByRange"),
  getAllFavoritesByPreset: post("moments/allFavoritesByRange"),
  getAllViewsByPreset: post("moments/allViewsByRange"),
  getViewsByPreset: post("moments/viewsByRange"),
  getOverView: post("moments/momentOverviewStats"),
  getOverViewRange: post("moments/allMomentOverviewByRange"),
  getAllOverViewStats: post("moments/allMomentOverviewStats"),
  getOrgLocationIds: post("moments/vps/locationsByOrgId"),
};

export const useMomentsService = (): MomentsService => {
  const getByLocation = async (
    data: IMomentRequest
  ): Promise<IMomentsResponse> => {
    try {
      const response = ENDPOINTS.getByLocation(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getAllMomentsSorted = async (data: {
    sortBy: string;
  }): Promise<IMomentsResponse> => {
    try {
      const response = ENDPOINTS.getAllMomentsSorted(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const removeMoments = async (data: {
    selectedMoments: string[];
  }): Promise<any> => {
    try {
      const response = ENDPOINTS.removeMoments(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const create = async (data: IMomentCreateRequest): Promise<any> => {
    try {
      const response = ENDPOINTS.createMoment(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const update = async (data: IMomentUpdateRequest): Promise<any> => {
    try {
      const response = ENDPOINTS.updateMoment(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getTagsByMoment = async (momentId: string): Promise<string[]> => {
    try {
      const response = ENDPOINTS.getTagsByMoment({ momentId });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFavoritesByPreset = async (momentId: string, preset: IPreset) => {
    try {
      const response = ENDPOINTS.getFavoritesByPreset({ momentId, preset });
      return response.then((res) => res["favorites"]);
    } catch (error) {
      throw error;
    }
  };

  const getViewsByPreset = async (momentId: string, preset: IPreset) => {
    try {
      const response = ENDPOINTS.getViewsByPreset({ momentId, preset });
      return response.then((res) => res["views"]);
    } catch (error) {
      throw error;
    }
  };

  const getAllFavoritesByPreset = async (preset: IPreset) => {
    try {
      const response = ENDPOINTS.getAllFavoritesByPreset({ preset });
      return response.then((res) => res["favorites"]);
    } catch (error) {
      throw error;
    }
  };

  const getAllViewsByPreset = async (preset: IPreset) => {
    try {
      const response = ENDPOINTS.getAllViewsByPreset({ preset });
      return response.then((res) => res["views"]);
    } catch (error) {
      throw error;
    }
  };

  const getOverView = async (momentId: string) => {
    try {
      const response = ENDPOINTS.getOverView({ momentId });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getAllMomentsOverView = async () => {
    try {
      const response = ENDPOINTS.getAllOverViewStats({});
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getOverViewRange = async (range: "month" | "day" | "week" | "year") => {
    try {
      const response = ENDPOINTS.getOverViewRange({ range });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getAllLocationIds = async () => {
    try {
      const response = ENDPOINTS.getOrgLocationIds({});
      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    getByLocation,
    getAllMomentsSorted,
    removeMoments,
    create,
    update,
    getTagsByMoment,
    getFavoritesByPreset,
    getViewsByPreset,
    getOverView,
    getOverViewRange,
    getAllLocationIds,
    getAllFavoritesByPreset,
    getAllViewsByPreset,
    getAllMomentsOverView,
  };
};
