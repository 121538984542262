import { useState, useEffect } from "react";

interface UseReverseGeocodingProps {
  latitude: number;
  longitude: number;
}

const useReverseGeocoding = ({
                               latitude,
                               longitude,
                             }: UseReverseGeocodingProps) => {
  const [locationName, setLocationName] = useState<string | null>(null);

  useEffect(() => {
    const accessToken =
      "pk.eyJ1Ijoibml0aGlsYW4tbGlmZWNhY2hlIiwiYSI6ImNsaTBqNTFseTBjZnQzc256cWg4NGl1YzYifQ.KutSc5nIKiQJ9cglVW2fCg";

    const reverseGeocode = async () => {
      try {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;
        const response = await fetch(endpoint);
        const data = await response.json();
        if (data?.features && data.features.length > 0) {
          const placeFeature = data.features.find((feature: { place_type: string | string[]; }) => feature.place_type.includes('place'));

          if (placeFeature) {
            setLocationName(placeFeature.place_name);
            return;
          }

          const firstFeature = data.features[0];
          const placeName = firstFeature.place_name;
          setLocationName(placeName);
        }
      } catch (error) {
        // Handle errors
        console.error("Error reverse geocoding:", error);
      }
    };

    reverseGeocode();
  }, [latitude, longitude]);

  return locationName;
};

export default useReverseGeocoding;
