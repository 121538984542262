import { Amplify, Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AwsConfigAuth } from "../config/auth";
import { UserProvideService } from "../services/userService";
import IUserRequest from "../types/user.req";
import IUserResponse from "../types/user.res";
import IUserProfileRequest from "../types/user.profile.req";

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => Promise<Result>;
  signUp: (
    username: string,
    password: string,
    email: string,
    fullname: string,
    companyName: string
  ) => Promise<Result>;
  signOut: () => void;
  getUser: () => Promise<IUserResponse>;
  cognitoUser: any;
  currentUser: any;
  currentSession: any;
  updateCurrentUser: () => void;
  updateUserProfile: (fullName: string, location: string, description: string) => Promise<Result>;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [currentSession, setCurrentSession] = useState<any>();
  const userService = UserProvideService();

  const initializeAuth = async () => {
    try {
      setIsLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setCognitoUser(cognitoUser);
      const session = await Auth.currentSession();

      setCurrentSession(session);
      const currentUser = await userService.getUser(cognitoUser.username);
      setCurrentUser(currentUser);
      setIsAuthenticated(true);

      setInterval(() => {
        cognitoUser.refreshSession(
          session?.getRefreshToken(),
          (err: any, s: any) => {
            setCurrentSession(s);
          }
        );
      }, 1200000);
      setIsLoading(false);
    } catch {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeAuth();
  }, [isAuthenticated]);

  const signIn = async (username: string, password: string) => {
    try {
      await Auth.signIn(username, password);

      const currentUser = await userService.getUser(username);
      setCurrentUser(currentUser);

      setIsAuthenticated(true);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGIN FAIL",
      };
    }
  };

  const signUp = async (
    username: string,
    password: string,
    email: string,
    fullname: string,
    companyName: string
  ) => {
    try {
      const { user, userConfirmed } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      await Auth.signIn(username, password);
      // TODO: Once user created in cognito, create in DB. Backend call {backend url}/users/create {username, id, email, name}
      const userRequest: IUserRequest = {
        username,
        email,
        fullName: fullname,
        companyName,
      };
      setCognitoUser(user);

      const createdUser = await userService.create(userRequest);

      // TODO: Store db user in context. Should set to db user.
      setCurrentUser(createdUser);

      // TODO: Create backend function for update {backend url}/users/update.

      if (userConfirmed) {
        setIsAuthenticated(true);
        return { success: true, message: "" };
      } else {
        return { success: false, message: "" };
      }
    } catch (e) {
      console.error(e);
      return {
        success: false,
        message: "SIGNUP FAIL",
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
      setCurrentSession(undefined);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGOUT FAIL",
      };
    }
  };

  const getUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      return await userService.getUser(cognitoUser.username);
    } catch (error) {
      throw error;
    }
  };

  const updateCurrentUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentUser = await userService.getUser(cognitoUser.username);
      setCurrentUser(currentUser);
    } catch (error) {
      throw error;
    }
  };

  const updateUserProfile = async (companyName: string, location: string, description: string) => {
    try {
      const userRequest: IUserProfileRequest = {
        username: cognitoUser.username,
        companyName,
        location,
        description
      };

      await userService.updateProfile(userRequest);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "UPDATE USER FAIL",
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    cognitoUser,
    currentUser,
    signIn,
    signOut,
    signUp,
    getUser,
    currentSession,
    updateCurrentUser,
    updateUserProfile,
  };
};
