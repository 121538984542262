import IMomentResponse from "../types/moment.res";
import { MomentItem } from "./MomentItem";

export const MomentGrid = ({
                             moments,
                             playVideo,
                             selectedMoments = [],
                             toggleSelection = (momentId: string) => {},
                             isSelectedMode = false
                           }: {
  moments: IMomentResponse[];
  playVideo: (url: string, poster: string, moment: IMomentResponse) => void;
  selectedMoments?: string[];
  toggleSelection?: (momentId: string) => void;
  isSelectedMode?: boolean;
}) => {
  return (
    <>
      <div
        className="momentScroll"
        style={{
          overflowX: "auto",
          width: "90vw",
          borderRadius: "16px",
        }}
      >
        {" "}
        {moments.map((m) => (
          <MomentItem
            key={m.id}
            moment={m}
            playVideo={playVideo}
            isSelected={selectedMoments.includes(m.id)}
            toggleSelection={toggleSelection}
            showSelectionIcon={isSelectedMode}
          />
        ))}
      </div>
    </>
  );
};
