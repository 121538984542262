import { Marker } from "react-map-gl";
import { Avatar } from "antd";
import IMomentResponse from "../types/moment.res";
import { ReactComponent as ProcessingImage } from "../images/processing_small.svg";

type LocationMarkerProps = {
  markerKey: string;
  latitude: number;
  longitude: number;
  image: string;
  id: string;
  moment: IMomentResponse;
  playVideo: (s: string, i: string, m: IMomentResponse) => void;
  url: string;
};

export const LocationMarker = ({
  markerKey,
  latitude,
  longitude,
  image,
  id,
  playVideo,
  url,
  moment,
}: LocationMarkerProps) => {
  return (
    <Marker
      key={markerKey}
      latitude={latitude}
      longitude={longitude}
      style={{ cursor: "pointer" }}
      anchor="bottom"
    >
      <div
        className="location-marker"
        id={id}
        onClick={() => {
          playVideo(url, image, moment);
        }}
      >
        <Avatar
          className="border-2 border-green-500 "
          style={{
            background: "#2C2C2C",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size={50}
          src={moment.status === "READY" ? image : <ProcessingImage />}
          alt="Location Image"
        />
        {/*<Icon icon={locationIcon} className="location-icon" />*/}
      </div>
    </Marker>
  );
};
