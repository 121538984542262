import React, {useEffect, useState, useRef} from 'react';
import {Tag} from "./Tag";
import {TagInput} from "./TagInput";

type TagsDisplayProps = {
  initialTags?: string[];
  onTagsChange?: (updatedTags: string[]) => void;
};

export const TagsDisplay: React.FC<TagsDisplayProps> = ({ initialTags = [], onTagsChange  }) => {
  const [tags, setTags] = useState<string[]>([]);
  const initialTagsRef = useRef(initialTags);

  useEffect(() => {
    if (JSON.stringify(initialTags) !== JSON.stringify(initialTagsRef.current)) {
      setTags(initialTags);
      initialTagsRef.current = initialTags;
    }
  }, [initialTags]);

  const removeTag = (tagToRemove: string) => {
    setTags(currentTags => {
      const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
      onTagsChange?.(updatedTags);
      return updatedTags;
    });
  };

  const addTag = (newTag: string) => {
    setTags(currentTags => {
      if (!currentTags.includes(newTag)) {
        const updatedTags = [...currentTags, newTag];
        onTagsChange?.(updatedTags);
        return updatedTags;
      }
      return currentTags;
    });
  };

  return (
    <div className="flex flex-col p-3">
      <TagInput onAddTag={addTag} />

      <div className="flex flex-wrap gap-2 p-3 rounded-lg">
        {tags.map(tag => (
          <Tag key={tag} text={tag} onRemove={removeTag} />
        ))}
      </div>

    </div>
  );
};