import React, {useEffect, useRef, useState} from "react";
import { ReactComponent as MenuIcon } from "../assets/svg/three-dots-icon.svg";

export enum MomentActionTypes {
    Edit = 'Edit',
    Insights = 'Insights',
    Delete = 'Delete'
}

type MomentActionsProps = {
    onOptionChanged: (option: MomentActionTypes) => void;
}

export const MomentActions: React.FC<MomentActionsProps> = ({onOptionChanged }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleSelect = (option: MomentActionTypes) => {
        setIsOpen(false);
        onOptionChanged(option);
    };

    return (
      <div className="relative inline-block text-left z-50" ref={dropdownRef}>
          <div
            className="flex items-center"
            style={{ cursor: "pointer" }}
            id="options-menu"
            onClick={() => setIsOpen(!isOpen)}
          >
              <MenuIcon className="w-12" />
          </div>

          {isOpen && (
            <div className="rounded-[10px] border-[1px] border-lifecache-color-white/40 border-solid origin-top-right absolute right-0 mt-3 w-24 bg-lifecache-color-gray/90 ">
                <div className="font-lifecache-body" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {Object.values(MomentActionTypes).map((option) => (
                      <a
                        key={option}
                        className={`border-b-[1px] 
                        border-b-lifecache-color-white/40 
                        last:border-b-0 border-t-0 border-l-0 border-r-0
                        border-solid  
                        block px-4 py-3 
                        text-sm 
                        hover:bg-lifecache-color-light-gray 
                        hover:text-lifecache-color-black
                        text-lifecache-color-white`}
                        role="menuitem"
                        onClick={() => handleSelect(option as MomentActionTypes)}
                      >
                          {option}
                      </a>
                    ))}
                </div>
            </div>
          )}
      </div>
    );
}