import {
  Button,
  Card,
  Col,
  Divider,
  Radio,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import IMomentResponse from "../types/moment.res";
import { ControlBar, Player } from "video-react";
import { TagsDisplay } from "./TagsDisplay";
import { IPreset, useMomentsService } from "../services/momentsService";
import { MomentsContext } from "./MomentsProvider";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface MomentInsightsProps {
  onClose: () => void;
  moment: IMomentResponse | undefined;
}

const CHART_PRESETS = [
    { key: IPreset.WEEK, value: "Last 7 Days" },
    { key: IPreset.BIWEEK, value: "Last 14 Days" },
    { key: IPreset.MONTH, value: "Last 30 Days" },
    { key: IPreset.YTD, value: "YTD" },
  ]

export const MomentInsights = ({ onClose, moment }: MomentInsightsProps) => {
  const { getFavoritesByPreset, getViewsByPreset, getOverView } = useMomentsService();

  const [selectedRangeLikes, setSelectedRangeLikes] = useState<IPreset>(IPreset.WEEK);
  const [selectedRangeViews, setSelectedRangeViews] = useState<IPreset>(IPreset.WEEK);

  const [selectedRangeLikesData, setSelectedRangeLikesData] = useState<{label: string, value: number}[]>([]);
  const [selectedRangeViewsData, setSelectedRangeViewsData] = useState<{label: string, value: number}[]>([]);
  const [overviewData, setOverviewData] = useState<{
    favorites: { value: number; changeThisWeek: number };
    views: { value: number; changeThisWeek: number };
    avgWatchTime: { value: number; changeThisWeek: number };
  }>();

  const getLikesData = async () => {
    setSelectedRangeLikesData((await getFavoritesByPreset(moment?.id!, selectedRangeLikes)));
  }

  const getViewsData = async () => {
    setSelectedRangeViewsData((await getViewsByPreset(moment?.id!, selectedRangeViews)));
  }

  const getOverviewData = async () => {
    setOverviewData(await getOverView(moment?.id!))
  }

  useEffect(() => {
    getLikesData();
  }, [selectedRangeLikes]);

  useEffect(() => {
    getViewsData();
  }, [selectedRangeViews]);

  useEffect(() => {
    getOverviewData();
  }, []);

  const renderAnalytic = (title: string, value: string, percent: string) => (
    <div
      style={{
        height: "150px",
        padding: "34px",
        width: "240px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: "#F3F3F3B3",
          fontSize: 14,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: "#F3F3F3",
          fontSize: 30,
        }}
      >
        {value}
      </div>
      <Divider style={{ borderTop: "1px solid #292929" }} />
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: percent.substring(0, 1) === "-" ? "#C71111" : "#57E163",
          fontSize: 15,
        }}
      >
        {percent}
      </div>
    </div>
  );

  return (
    <>
      <style>
        {`
                  .scrollable-content::-webkit-scrollbar {
                    display: none;
                  }
                  .scrollable-content {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                  }
                  :where(label).ant-radio-button-wrapper:not(:first-child)::before {
                    content: none !important;
                  }
            `}
      </style>
      <div className="z-40 fixed top-10 left-1/2 w-1/2 transform -translate-x-1/2 bg-lifecache-color-black rounded-2xl shadow-lg">
        <div className="bg-lifecache-color-white/20 p-8 pt-4 rounded-2xl shadow-lg">
          <div className="flex justify-end">
            <Button
              className="bg-lifecache-color-white/20 text-black m-1"
              style={{ border: "inherit" }}
              shape="circle"
              size="small"
              onClick={onClose}
              icon={<CloseOutlined />}
            />
          </div>

          <div className="scrollable-content overflow-y-auto max-h-[calc(100vh-10rem)]">
            <div
              className="top-1/2 left-1/2 w-full transform mt-4"
              style={{
                borderRadius: "16px",
                overflow: "hidden",
                width: "100%",
                height: 462.5,
              }}
            >
              {/* @ts-ignore */}
              <Player playsInline poster={moment.thumbnail_url} src={moment.url} fluid={false} width={"100%"} height={"100%"}
              >
                <ControlBar autoHide={true}></ControlBar>
              </Player>
            </div>

            <div
              style={{
                fontFamily: "Rubik Mono One",
                color: "#F3F3F3",
                fontSize: 30,
                marginTop: 15,
              }}
            >
              MOMENT INSIGHTS
            </div>

            <Row>
              <div
                style={{
                  minHeight: "74px",
                  width: "100%",
                  marginBottom: '10px'
                }}
              >
                <Typography.Title
                  level={5}
                  style={{ color: "white", margin: 0, marginTop: 20 }}
                >
                  {moment?.name}
                </Typography.Title>
                <Typography.Text style={{ color: "white", margin: 0 }}>
                  {moment?.description}
                </Typography.Text>
              </div>
            </Row>

            <Row>
              <Col span={24}>
                <Card
                  style={{
                    background: "transparent",
                    height: "230px",
                    borderColor: "#787878",
                    marginRight: "18px",
                  }}
                  bodyStyle={{
                    padding: 5,
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  {renderAnalytic("LIKES", `${overviewData?.favorites.value || 0}`, `+${overviewData?.favorites.changeThisWeek || 0}%`)}
                  <Divider
                    type="vertical"
                    style={{ borderLeft: "1px solid #292929", height: "200px" }}
                  />
                  {renderAnalytic("VIEWS", `${overviewData?.views.value || 0}`, `+${overviewData?.views.changeThisWeek || 0}%`)}
                  <Divider
                    type="vertical"
                    style={{ borderLeft: "1px solid #292929", height: "200px" }}
                  />
                  {renderAnalytic("AVG WATCH TIME", `${Math.floor((overviewData?.avgWatchTime.value || 0) / 60).toString()}:${((overviewData?.avgWatchTime.value || 0) % 60)
                        .toString()
                        .padStart(2, "0") || 0} min`, `+${overviewData?.avgWatchTime.changeThisWeek || 0}%`)}
                </Card>
              </Col>
            </Row>

            <div
              style={{
                fontFamily: "Rubik Mono One",
                color: "#F3F3F3",
                fontSize: 15,
                marginTop: 25,
                marginBottom: 15,
              }}
            >
              MOMENT LIKES
            </div>

            <Row>
              <Col span={24}>
                <Card
                  style={{
                    background: "transparent",
                    height: "400px",
                    borderColor: "#787878",
                    marginRight: "18px",
                  }}
                  bodyStyle={{
                    padding: 0,
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Row
                    style={{ height: "15%", width: "100%", padding: 20 }}
                    justify={"end"}
                  >
                    <Radio.Group
                      onChange={(v) => setSelectedRangeLikes(v.target.value)}
                      size="small"
                      value={selectedRangeLikes}
                    >
                      {CHART_PRESETS.map(({ key, value }) => (
                        <Radio.Button
                          value={key}
                          style={{
                            background: key !== selectedRangeLikes ? "transparent" : "white",
                            borderColor: key !== selectedRangeLikes ? "#787878" : "white",
                            color: key !== selectedRangeLikes ? "#787878": "#343434",
                            borderWidth: "1px",
                            borderRadius: 15,
                            margin: 5,
                            borderInlineStartWidth: 1,
                          }}
                        >
                          {value}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Row>
                  <ResponsiveContainer width="100%" height="85%">
                    <LineChart
                      data={selectedRangeLikesData}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 10,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} stroke="#787878" />
                      <XAxis dataKey="label" stroke="#787878" />
                      <YAxis stroke="#787878" axisLine={false} />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#00FFFF"
                        activeDot={{ r: 8 }}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>

            <div
              style={{
                fontFamily: "Rubik Mono One",
                color: "#F3F3F3",
                fontSize: 15,
                marginTop: 25,
                marginBottom: 15,
              }}
            >
              MOMENT VIEWS
            </div>

            <Row>
              <Col span={24}>
                <Card
                  style={{
                    background: "transparent",
                    height: "400px",
                    borderColor: "#787878",
                    marginRight: "18px",
                  }}
                  bodyStyle={{
                    padding: 0,
                    borderRadius: "16px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Row
                    style={{ height: "15%", width: "100%", padding: 20 }}
                    justify={"end"}
                  >
                    <Radio.Group
                      onChange={(v) => setSelectedRangeViews(v.target.value)}
                      defaultValue="a"
                      size="small"
                    >
                      {CHART_PRESETS.map(({ key, value }) => (
                        <Radio.Button
                          value={key}
                          style={{
                            background: key !== selectedRangeViews ? "transparent" : "white",
                            borderColor: key !== selectedRangeViews ? "#787878" : "white",
                            color: key !== selectedRangeViews ? "#787878": "#343434",
                            borderWidth: "1px",
                            borderRadius: 15,
                            margin: 5,
                            borderInlineStartWidth: 1,
                          }}
                        >
                          {value}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Row>
                  <ResponsiveContainer width="100%" height="85%">
                    <LineChart
                      data={selectedRangeViewsData}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 10,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} stroke="#787878" />
                      <XAxis dataKey="label" stroke="#787878" />
                      <YAxis stroke="#787878" axisLine={false} />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#57E163"
                        activeDot={{ r: 8 }}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
