import { useEffect, useState } from "react";
import { IPreset, useMomentsService } from "../services/momentsService";
import IMomentResponse from "../types/moment.res";
import {
  Avatar,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Radio,
  Row,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";

const CHART_PRESETS = [
  { key: IPreset.WEEK, value: "Last 7 Days" },
  { key: IPreset.BIWEEK, value: "Last 14 Days" },
  { key: IPreset.MONTH, value: "Last 30 Days" },
  { key: IPreset.YTD, value: "YTD" },
];

const TOPN_PRESETS = [
  { key: 3, value: "Top 3" },
  { key: 5, value: "Top 5" },
  { key: 10, value: "Top 10" },
];

const TOP_TIME_PRESETS = [
  { key: "day", value: "Top Day" },
  { key: "week", value: "Top Week" },
  { key: "month", value: "Top Month" },
  { key: "year", value: "Top Year" },
];

function stringToColour(str: string) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return `${colour.substring(0, 3)}FF${colour.substring(5)}`;
}

export const TotalInsights = ({ moments }: { moments: IMomentResponse[] }) => {
  const {
    getOverViewRange,
    getAllFavoritesByPreset,
    getAllViewsByPreset,
    getAllMomentsOverView,
  } = useMomentsService();

  const [selectedRangeLikes, setSelectedRangeLikes] = useState<IPreset>(
    IPreset.WEEK
  );
  const [selectedRangeViews, setSelectedRangeViews] = useState<IPreset>(
    IPreset.WEEK
  );

  const [selectedTopN, setSelectedTopN] = useState<number>(3);
  const [selectedTopTime, setSelectedTopTime] = useState<
    "day" | "month" | "week" | "year"
  >("day");
  const [selectedAnalytic, setSelectedAnalytic] = useState("TOTAL LIKES");
  const [expanded, setExpanded] = useState(false);
  const [selectedTopTimeData, setSelectedTopTimeData] = useState<{
    favorites: {
      favorites: number;
      momentId: string;
      views: number;
      avgWatchTime: number;
      date: string;
    }[];
    views: {
      favorites: number;
      momentId: string;
      views: number;
      avgWatchTime: number;
      date: string;
    }[];
    avgWatchTime: {
      favorites: number;
      momentId: string;
      views: number;
      avgWatchTime: number;
      date: string;
    }[];
  }>();

  const [selectedRangeLikesData, setSelectedRangeLikesData] = useState<{
    [momentId: string]: { label: string; value: number }[];
  }>({});
  const [selectedRangeViewsData, setSelectedRangeViewsData] = useState<{
    [momentId: string]: { label: string; value: number }[];
  }>({});
  const [overviewData, setOverviewData] = useState<{
    [momentId: string]: {
      favorites: { value: number; changeThisWeek: number };
      views: { value: number; changeThisWeek: number };
      avgWatchTime: { value: number; changeThisWeek: number };
      momentId: string;
      momentName: string;
      moment: IMomentResponse;
    };
  }>({});

  const getLikesData = async () => {
    setSelectedRangeLikesData(
      await getAllFavoritesByPreset(selectedRangeLikes)
    );
  };

  const getViewsData = async () => {
    setSelectedRangeViewsData(await getAllViewsByPreset(selectedRangeViews));
  };

  const getOverviewData = async () => {
    setOverviewData(
      Object.entries(await getAllMomentsOverView()).reduce(
        (acc, [momentId, value]) => {
          const m = moments.find((m) => m.id === momentId);
          return {
            ...acc,
            [momentId]: {
              ...value,
              momentId,
              moment: m,
              momentName: m?.name,
            },
          };
        },
        {}
      )
    );
  };

  const getOverviewRange = async () => {
    const res = await getOverViewRange(selectedTopTime);
    setSelectedTopTimeData(res);
  };

  useEffect(() => {
    if (!expanded) return;
    getLikesData();
  }, [selectedRangeLikes, expanded]);

  useEffect(() => {
    if (!expanded) return;
    getViewsData();
  }, [selectedRangeViews, expanded]);

  useEffect(() => {
    getOverviewData();
  }, [moments]);

  useEffect(() => {
    if (!expanded) return;
    getOverviewRange();
  }, [selectedTopTime, expanded]);

  const headerChoices = ["TOTAL LIKES", "TOTAL VIEWS", "AVG WATCH TIME"];

  const renderAnalytic = (title: string, value: string, percent: string) => (
    <div
      style={{
        height: "205px",
        padding: "34px",
        width: "240px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: "#F3F3F3B3",
          fontSize: 14,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: "#F3F3F3",
          fontSize: 44,
        }}
      >
        {value}
      </div>
      <Divider style={{ borderTop: "1px solid #292929" }} />
      <div
        style={{
          fontFamily: "Rubik Mono One",
          color: percent.substring(0, 1) === "-" ? "#C71111" : "#57E163",
          fontSize: 25,
        }}
      >
        {percent}
      </div>
    </div>
  );

  const topFavs = Object.keys(overviewData || {})
    .sort(
      (a, b) =>
        -overviewData[a].favorites.value + overviewData[b].favorites.value
    )
    .map((d) => overviewData[d])
    .slice(0, selectedTopN);

  const topViews = Object.keys(overviewData || {})
    .sort((a, b) => -overviewData[a].views.value + overviewData[b].views.value)
    .map((d) => overviewData[d])
    .slice(0, selectedTopN);

  const topFavsRange = topFavs.map((fav) => ({
    data: selectedRangeLikesData[fav.momentId],
    momentId: fav.momentId,
  }));

  const topViewsRange = topFavs.map((fav) => ({
    data: selectedRangeViewsData[fav.momentId],
    momentId: fav.momentId,
  }));

  const top =
    selectedAnalytic === headerChoices[0]
      ? topFavs
      : selectedAnalytic === headerChoices[1]
      ? topViews
      : topFavs;
  const topRange =
    selectedAnalytic === headerChoices[0]
      ? topFavsRange
      : selectedAnalytic === headerChoices[1]
      ? topViewsRange
      : topFavsRange;

  const totalLikes = Object.keys(overviewData)
    .reduce(
      (prev, next) => (prev += overviewData[next].favorites.value || 0),
      0
    )
    .toString();

  const totalViews = Object.keys(overviewData)
    .reduce((prev, next) => (prev += overviewData[next].views.value || 0), 0)
    .toString();
  const totalAvgWatch = Object.keys(overviewData).reduce(
    (prev, next) => (prev += overviewData[next].avgWatchTime.value || 0),
    0
  );

  if (
    (_.isEmpty(overviewData) ||
      _.isEmpty(selectedRangeLikesData) ||
      _.isEmpty(selectedRangeViewsData)) &&
    expanded &&
    !_.isEmpty(moments)
  ) {
    return (
      <>
        <div
          style={{
            width: "90vw",
            textAlign: "left",
            marginBottom: -10,
            fontFamily: "Rubik Mono One",
            fontSize: 20,
          }}
        >
          <h3>STATS</h3>
        </div>
        <div
          style={{
            display: "flex",
            overflowY: "scroll",
            width: "90vw",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          className="momentScroll mb-5"
        >
          <Card
            style={{
              background: "transparent",
              height: "285px",
              borderColor: "#787878",
              marginRight: "18px",
            }}
            bodyStyle={{
              padding: 5,
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {renderAnalytic("TOTAL LIKES", totalLikes, "+0.00%")}
            <Divider
              type="vertical"
              style={{ borderLeft: "1px solid #292929", height: "240px" }}
            />
            {renderAnalytic("TOTAL VIEWS", totalViews, "+0.00%")}
          </Card>

          <Card
            style={{
              background: "transparent",
              height: "285px",
              borderColor: "#787878",
              marginRight: "18px",
            }}
            bodyStyle={{
              padding: 5,
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {renderAnalytic(
              "AVERAGE WATCH TIME",
              `${Math.floor(totalAvgWatch / 60).toString()}:
                      ${Math.round(totalAvgWatch % 60)
                        .toString()
                        .padStart(2, "0")}` + "M",
              "+0.00%"
            )}
          </Card>

          {/* <Card
              style={{
                background: "transparent",
                height: "285px",
                borderColor: "#787878",
                marginRight: "18px",
              }}
              bodyStyle={{
                padding: 5,
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {renderBubbleAnalytic("TOP CITIES", TempCitiea)}
            </Card>

            <Card
              style={{
                background: "transparent",
                height: "285px",
                borderColor: "#787878",
                marginRight: "18px",
              }}
              bodyStyle={{
                padding: 5,
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {renderBubbleAnalytic("GENDER", TempGender)}
            </Card> */}
        </div>
        <div
          style={{
            width: 178,
            height: 44,
            borderRadius: 24,
            background: "#FFFFFFCC",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            color: "black",
            fontWeight: "bold",
          }}
        >
          <div>All Insights</div>
          <Switch
            onChange={(e) => setExpanded(e.valueOf())}
            checked={expanded}
            disabled={_.isEmpty(moments)}
          />
        </div>
        <Row style={{ width: "100%" }} align={"middle"} justify={"center"}>
          <Spin />
        </Row>
      </>
    );
  }

  const selectedRange =
    selectedAnalytic === headerChoices[0]
      ? selectedRangeLikes
      : selectedAnalytic === headerChoices[1]
      ? selectedRangeViews
      : selectedRangeViews;

  const selectedRangeUpdateFn =
    selectedAnalytic === headerChoices[0]
      ? setSelectedRangeLikes
      : selectedAnalytic === headerChoices[1]
      ? setSelectedRangeViews
      : setSelectedRangeViews;

  return (
    <>
      <style>
        {`
                  .scrollable-content::-webkit-scrollbar {
                    display: none;
                  }
                  .scrollable-content {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                  }
                  :where(label).ant-radio-button-wrapper:not(:first-child)::before {
                    content: none !important;
                  }
            `}
      </style>
      <div
        style={{
          width: "90vw",
          textAlign: "left",
          marginBottom: -10,
          fontFamily: "Rubik Mono One",
          fontSize: 20,
        }}
      >
        <h3>STATS</h3>
      </div>
      <div
        style={{
          display: "flex",
          overflowY: "scroll",
          width: "90vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        className="momentScroll mb-5"
      >
        <Card
          style={{
            background: "transparent",
            height: "285px",
            borderColor: "#787878",
            marginRight: "18px",
          }}
          bodyStyle={{
            padding: 5,
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {renderAnalytic("TOTAL LIKES", totalLikes, "+0.00%")}
          <Divider
            type="vertical"
            style={{ borderLeft: "1px solid #292929", height: "240px" }}
          />
          {renderAnalytic("TOTAL VIEWS", totalViews, "+0.00%")}
        </Card>

        <Card
          style={{
            background: "transparent",
            height: "285px",
            borderColor: "#787878",
            marginRight: "18px",
          }}
          bodyStyle={{
            padding: 5,
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          {renderAnalytic(
            "AVERAGE WATCH TIME",
            `${Math.floor(totalAvgWatch / 60).toString()}:${Math.round(totalAvgWatch % 60)
                        .toString()
                        .padStart(2, "0")}` + "m",
            "+0.00%"
          )}
        </Card>

        {/* <Card
              style={{
                background: "transparent",
                height: "285px",
                borderColor: "#787878",
                marginRight: "18px",
              }}
              bodyStyle={{
                padding: 5,
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {renderBubbleAnalytic("TOP CITIES", TempCitiea)}
            </Card>

            <Card
              style={{
                background: "transparent",
                height: "285px",
                borderColor: "#787878",
                marginRight: "18px",
              }}
              bodyStyle={{
                padding: 5,
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {renderBubbleAnalytic("GENDER", TempGender)}
            </Card> */}
      </div>
      <div
        style={{
          width: 178,
          height: 44,
          borderRadius: 24,
          background: "#FFFFFFCC",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          color: "black",
          fontWeight: "bold",
        }}
      >
        <div>All Insights</div>
        <ConfigProvider
          theme={{
            components: {
              Switch: {
                colorPrimary: "#34C759",
              },
            },
          }}
        >
          <Switch
            onChange={(e) => setExpanded(e.valueOf())}
            checked={expanded}
            disabled={_.isEmpty(moments)}
          />
        </ConfigProvider>
      </div>
      {expanded && (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: 100,
              marginBottom: 10,
              borderBottom: "1px solid #FFFFFF1A",
            }}
          >
            {headerChoices.map((header) => (
              <div
                style={{
                  textAlign: "left",
                  marginBottom: -10,
                  fontFamily: "Rubik Mono One",
                  fontSize: 20,
                  marginRight: 90,
                  color: header === selectedAnalytic ? "#FFFFFF" : "#F3F3F366",
                  cursor: "pointer",
                }}
              >
                <h3 onClick={() => setSelectedAnalytic(header)}>{header}</h3>
              </div>
            ))}
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: -10,
              fontFamily: "Rubik Mono One",
              fontSize: 10,
              marginRight: 90,
              color: "#FFFFFF",
            }}
          >
            <h4>
              {selectedAnalytic === headerChoices[0]
                ? "TOP LIKED MOMENTS"
                : selectedAnalytic === headerChoices[1]
                ? "TOP VIEWED MOMENTS"
                : "TOP WATCHED MOMENTS"}
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <PieChart width={500} height={500}>
              <Pie
                data={top.map((d) => ({
                  name: d.momentName,
                  value:
                    d?.[
                      selectedAnalytic === headerChoices[0]
                        ? "favorites"
                        : selectedAnalytic === headerChoices[1]
                        ? "views"
                        : "views"
                    ].value,
                  moment: d.moment,
                }))}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={100}
                outerRadius={200}
                fill="#4D4D4D"
                labelLine={false}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  percent,
                  moment,
                  value,
                  index,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.5;
                  const x = cx + radius * Math.cos(-midAngle * RADIAN) - 15;
                  const y = cy + radius * Math.sin(-midAngle * RADIAN) - 10;

                  return (
                    <>
                      <svg width="100" height="250" x={x} y={y}>
                        <defs>
                          <clipPath id="myCircle">
                            <circle
                              r="20"
                              cx="25"
                              cy="25"
                              fill="red"
                              stroke="green"
                              stroke-width="3"
                              opacity="0.5"
                            />
                          </clipPath>
                        </defs>
                        <g>
                          <image
                            width="100"
                            height="100"
                            x={-25}
                            y={-25}
                            stroke="blue"
                            stroke-width="3"
                            xlinkHref={moment.thumbnail_url}
                            clip-path="url(#myCircle)"
                          />
                        </g>
                      </svg>
                    </>
                  );
                }}
              >
                {top.map((t) => (
                  <Cell
                    fill={stringToColour(t.momentId)}
                    style={{ borderRight: "10px solid black" }}
                  />
                ))}
              </Pie>
            </PieChart>
            <div style={{ marginTop: "-30px" }}>
              <Row
                style={{ height: "15%", width: "100%", padding: 20 }}
                justify={"end"}
              >
                <Radio.Group
                  onChange={(v) => setSelectedTopN(v.target.value)}
                  size="small"
                  value={selectedTopN}
                >
                  {TOPN_PRESETS.map(({ key, value }) => (
                    <Radio.Button
                      value={key}
                      style={{
                        background:
                          key !== selectedTopN ? "transparent" : "white",
                        borderColor: key !== selectedTopN ? "#787878" : "white",
                        color: key !== selectedTopN ? "#787878" : "#343434",
                        borderWidth: "1px",
                        borderRadius: 15,
                        margin: 5,
                        borderInlineStartWidth: 1,
                      }}
                    >
                      {value}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Row>
              {top.map((fav, i) => (
                <div
                  style={{
                    width: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderBottom: "1px solid #FFFFFF1A",
                  }}
                >
                  <div
                    style={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Avatar
                      className="border-2 "
                      style={{
                        background: "#2C2C2C",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: stringToColour(fav.momentId),
                      }}
                      size={50}
                      src={fav.moment?.thumbnail_url}
                      alt="Location Image"
                    />
                    <div style={{ width: 100 }}>
                      {i + 1}. {fav.momentName}
                    </div>
                  </div>
                  <div
                    style={{
                      width: 350,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{fav.favorites.value} Likes</div>
                    <div>{fav.views.value} Views</div>
                    <div>
                      {Math.floor(fav.avgWatchTime.value / 60).toString()}:
                      {(fav.avgWatchTime.value % 60)
                        .toString()
                        .padStart(2, "0")}
                      m
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: -10,
              fontFamily: "Rubik Mono One",
              fontSize: 10,
              marginRight: 90,
              color: "#FFFFFF",
            }}
          >
            <h4>
              {selectedAnalytic === headerChoices[0]
                ? "LIKES TIMELINE"
                : selectedAnalytic === headerChoices[1]
                ? "VIEWS TIMELINE"
                : "WATCH TIME TIMELINE"}
            </h4>
          </div>
          <Row style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Card
                style={{
                  background: "transparent",
                  height: "400px",
                  borderColor: "#787878",
                  marginRight: "18px",
                }}
                bodyStyle={{
                  padding: 0,
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Row
                  style={{ height: "15%", width: "100%", padding: 20 }}
                  justify={"end"}
                >
                  <Radio.Group
                    onChange={(v) => selectedRangeUpdateFn(v.target.value)}
                    size="small"
                    value={selectedRange}
                  >
                    {CHART_PRESETS.map(({ key, value }) => (
                      <Radio.Button
                        value={key}
                        style={{
                          background:
                            key !== selectedRange ? "transparent" : "white",
                          borderColor:
                            key !== selectedRange ? "#787878" : "white",
                          color: key !== selectedRange ? "#787878" : "#343434",
                          borderWidth: "1px",
                          borderRadius: 15,
                          margin: 5,
                          borderInlineStartWidth: 1,
                        }}
                      >
                        {value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Row>
                <ResponsiveContainer width="100%" height="85%">
                  <LineChart
                    margin={{
                      top: 10,
                      right: 30,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#787878" />
                    <XAxis
                      dataKey="label"
                      stroke="#787878"
                      allowDuplicatedCategory={false}
                    />
                    <YAxis stroke="#787878" dataKey="value" axisLine={false} />
                    <Tooltip />
                    {topRange.map((fav) => (
                      <Line
                        data={fav.data}
                        type="monotone"
                        dataKey="value"
                        stroke={stringToColour(fav.momentId)}
                        activeDot={{ r: 8 }}
                        dot={false}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Col>
          </Row>
          <Row
            style={{ width: "100%", marginBottom: 20 }}
            align={"middle"}
            justify={"space-evenly"}
          >
            {top.map((fav, i) => (
              <Col>
                <Row
                  align={"middle"}
                  style={{ margin: "5px" }}
                  justify={"space-between"}
                >
                  <Avatar
                    className="border-2 "
                    style={{
                      background: "#2C2C2C",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "5px",
                      borderColor: stringToColour(fav.momentId),
                    }}
                    size={30}
                    src={fav.moment?.thumbnail_url}
                    alt="Location Image"
                  />
                  <div>
                    {i + 1}. {fav.momentName}
                  </div>
                </Row>
              </Col>
            ))}
          </Row>
          <Row style={{ width: "100%", marginBottom: 40 }} align={"middle"}>
            <Row
              style={{ height: "15%", width: "100%", padding: 20 }}
              justify={"end"}
            >
              <Radio.Group
                onChange={(v) => setSelectedTopTime(v.target.value)}
                size="small"
                value={selectedTopTime}
              >
                {TOP_TIME_PRESETS.map(({ key, value }) => (
                  <Radio.Button
                    value={key}
                    style={{
                      background:
                        key !== selectedTopTime ? "transparent" : "white",
                      borderColor:
                        key !== selectedTopTime ? "#787878" : "white",
                      color: key !== selectedTopTime ? "#787878" : "#343434",
                      borderWidth: "1px",
                      borderRadius: 15,
                      margin: 5,
                      borderInlineStartWidth: 1,
                    }}
                  >
                    {value}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Row>
            {selectedTopTimeData?.[
              selectedAnalytic === headerChoices[0]
                ? "favorites"
                : selectedAnalytic === headerChoices[1]
                ? "views"
                : "views"
            ]
              .slice(0, selectedTopN)
              .map((fav, i) => {
                const m = moments.find((k) => k.id === fav.momentId);
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: 5,
                      marginBottom: 5,
                      borderBottom: "1px solid #FFFFFF1A",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar
                        className="border-2 "
                        style={{
                          background: "#2C2C2C",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: stringToColour(m?.id || ""),
                        }}
                        size={50}
                        src={m?.thumbnail_url}
                        alt="Location Image"
                      />
                      <div style={{ width: 300, marginLeft: "30px" }}>
                        {i + 1}. {m?.name}
                      </div>
                    </div>
                    <div
                      style={{
                        width: 350,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{fav.date}</div>
                      <div>{fav.favorites} Likes</div>
                      <div>{fav.views} Views</div>
                      <div>{fav.views}m</div>
                    </div>
                  </div>
                );
              })}
          </Row>
        </>
      )}
    </>
  );
};
