import React from 'react';

type TagProps = {
  text: string;
  onRemove: (text: string) => void;
};

export const Tag: React.FC<TagProps> = ({ text, onRemove }) => {
  return (
    <div className="flex flex-row items-center text-black text-sm pl-4 pr-2 py-1 rounded-full w-fit h-[29px] bg-lifecache-color-white/20">
      <span>{text}</span>
      <button
        onClick={() => onRemove(text)}
        className="bg-transparent mr-0 focus:outline-none border-none text-2xl h-[29px]"
      >
        &times;
      </button>
    </div>
  );
};
