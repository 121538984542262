import React from 'react';
import {ControlBar, Player} from "video-react";
import {ReactComponent as CloseButton} from "../images/close.svg";
import {ReactComponent as Views} from "../images/views.svg";
import {ReactComponent as Favorites} from "../images/favorite.svg";
import {Col, Row, Typography} from "antd";
import IMomentResponse from "../types/moment.res";
import {MomentActions, MomentActionTypes} from "./MomentActions";

export const MomentPlayer = (
  {
    setOpenVideo,
    poster,
    playingVideo,
    activeMoment,
    onMomentAction,
  } : {
    setOpenVideo: (open: boolean) => void;
    poster: string;
    playingVideo: string;
    activeMoment: IMomentResponse | undefined;
    onMomentAction: (action: MomentActionTypes) => void;
  }
) => {
  return (
    <div
      className="z-40 fixed top-1/2 left-1/2 w-1/2 transform -translate-x-1/2 -translate-y-1/2"
      style={{borderRadius: "10px", overflow: "hidden", width: 750, height: 462.5}}
    >
      {/* @ts-ignore */}
      <Player playsInline autoPlay poster={poster} src={playingVideo} fluid={false} width={'100%'} height={'100%'}>
        <ControlBar className="z-50" autoHide={true}></ControlBar>
      </Player>
      <div
        style={{
          top: 0,
          right: 0,
          position: "absolute",
          margin: "14px 12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "200px",
        }}
      >
        <CloseButton
          onClick={() => setOpenVideo(false)}
          style={{cursor: "pointer"}}
        />
        <Col>
          <Favorites/>
          <Row justify={"center"}>
            <Typography.Text style={{color: "white", fontSize: "12px"}}>
              {activeMoment?.favorites}
            </Typography.Text>
          </Row>
        </Col>
        <Col style={{textAlign: "center"}}>
          <Views/>
          <Row justify={"center"}>
            <Typography.Text style={{color: "white", fontSize: "12px"}}>
              {activeMoment?.views}
            </Typography.Text>
          </Row>
        </Col>

        <Col style={{textAlign: "center"}}>
          <MomentActions onOptionChanged={ onMomentAction }/>
        </Col>

      </div>
      <div
        style={{
          bottom: 0,
          position: "absolute",
          height: "74px",
          background: "#00000033",
          width: "100%",
          padding: "9px 16px 30px 16px",
          zIndex: 0,
        }}
      >
        <Typography.Title level={5} style={{color: "white", margin: 0}}>
          {activeMoment?.name}
        </Typography.Title>
        <Typography.Text style={{color: "white", margin: 0}}>
          {activeMoment?.description}
        </Typography.Text>
      </div>
    </div>
  );
}
