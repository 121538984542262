import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Input,
  List,
  message,
  Progress,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { ReactComponent as UploadFileIcon } from "../assets/svg/upload-file.svg";
import useForwardGeocoding from "../hooks/useForwardGeocoding";
import Place from "../types/place";
import { MapViewSimple } from "./MapViewSimple";
import { CloseOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import AWS from "aws-sdk";
import { useMomentsService } from "../services/momentsService";
import { useAuth } from "../hooks/useAuth";
import IMomentResponse from "../types/moment.res";
import { FaLink } from "react-icons/fa6";

const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

interface UploadMomentProps {
  onClose: () => void;
  onUploaded: () => void;
}

const S3_BUCKET_VIDEO = "lifecache-moments-upload";
const S3_BUCKET_IMAGE = "lifecache-moments";
const REGION = "us-east-1";
const IMAGE_FORMATS = ["png", "jpg", "jpeg"];

AWS.config.update({
  accessKeyId: "AKIAVXUF3EPDRUIWEV4Z",
  secretAccessKey: "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",
});
const s3 = new AWS.S3({
  region: REGION,
});

export type IMomentSrcType = "video" | "image" | "360" | undefined;

export const UploadMoment = ({ onClose, onUploaded }: UploadMomentProps) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [srcType, setSrcType] = useState<IMomentSrcType>();
  const [selectedPlace, setSelectedPlace] = useState<Place>({
    place_name: "",
    center: [0, 0],
  });
  const { currentUser } = useAuth();
  const momentsService = useMomentsService();
  const { TextArea } = Input;
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [[lat, lng], setLatLng] = useState<[number | null, number | null]>([
    null,
    null,
  ]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [callActionButtonText, setCallActionButtonText] = useState<string>("");
  const [callActionButtonLink, setCallActionButtonLink] =
    useState<string>("https://");
  const [locationType, setLocationType] = useState<number>(1);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [orgLocationIds, setOrgLocationIds] = useState<
    { id: string; locationName: string }[] | undefined
  >(undefined);

  useEffect(() => {
    fetchLocationIds();
  }, []);

  const fetchLocationIds = async () => {
    setOrgLocationIds((await momentsService.getAllLocationIds()).locations);
  };

  const handleSelectedPlace = (place: Place) => {
    setSelectedPlace(place);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file as RcFile);
    });
    setUploading(true);

    let file = fileList[0];
    const format = file.name.split(".")?.pop();

    if (!format) {
      message.error("uploaded file is not a media file. Try again.");
      return;
    }
    const bucket = srcType === "image" ? S3_BUCKET_IMAGE : S3_BUCKET_VIDEO;

    const moment: IMomentResponse = await momentsService.create({
      altitude: 0,
      description: description,
      name: title,
      url: `https://${bucket}.s3.amazonaws.com/${file.name}`,
      username: currentUser.username,
      userId: currentUser.userId,
      orgId: currentUser.orgId,
      srcType: srcType || "",
      callToActionText: callActionButtonText,
      callToActionLink:
        callActionButtonLink === "https://" ? "" : callActionButtonLink,
      ...(locationType === 2
        ? {
            latitude: lat || selectedPlace.center[1],
            longitude: lng || selectedPlace.center[0],
          }
        : {
            locationId: selectedLocationId,
          }),
    });

    const key = `${moment.id}/${moment.id}.${
      moment.srcType === "image" ? "jpg" : format
    }`;

    const params = {
      Bucket: bucket,
      Key: key,
      Body: file,
    };

    var upload = s3
      .upload(params)
      .on("httpUploadProgress", (evt) => {
        //@ts-ignore
        setUploadProgress(parseInt((evt.loaded * 100) / evt.total));
      })
      .promise();

    await upload.then((res: any) => {
      setUploadProgress(0);
      if (res) {
        message.success("upload successfully.");
      } else {
        message.error("upload failed.");
      }
    });

    setUploading(false);
    onUploaded();
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  const address = useForwardGeocoding({
    initialValue: "",
  });

  return (
    <>
      <div className="z-40 absolute top-10 left-1/2 w-1/3 transform -translate-x-1/2 bg-lifecache-color-black rounded-2xl shadow-lg">
        <div className="bg-lifecache-color-white/20 p-8 rounded-2xl shadow-lg">
          <div className="flex justify-end">
            <Button
              className="bg-lifecache-color-white/20 text-white/40"
              style={{ border: "inherit" }}
              shape="circle"
              size="small"
              onClick={onClose}
              icon={<CloseOutlined />}
            />
          </div>

          <span className="text-2xl">Upload a moment</span>

          <div className="bg-lifecache-color-white/10 text-white rounded-2xl mt-5">
            <Dragger
              {...props}
              style={{ border: "inherit" }}
              listType="picture-card"
              accept="video/mp4,video/x-m4v,video/*,image/*"
              multiple={false}
              onRemove={() => setFileList([])}
              maxCount={1}
            >
              {fileList.length === 0 ? (
                <>
                  <p>
                    <UploadFileIcon />
                  </p>
                  <p className="text-white">Upload video or photo</p>
                </>
              ) : (
                <>
                  <p>
                    <CheckCircleOutlined
                      style={{ color: "green", fontSize: "50px" }}
                    />
                  </p>
                  <p className="text-white">Click to edit upload</p>
                </>
              )}
            </Dragger>
          </div>

          <Row justify={"center"} align={"middle"}>
            <div className="mt-5">
              Content Type:{" "}
              <Radio.Group
                onChange={(e) => setSrcType(e.target.value)}
                value={srcType}
                buttonStyle="solid"
              >
                <Radio value="video" style={{ color: "white" }}>
                  Video
                </Radio>
                <Radio value="360" style={{ color: "white" }}>
                  360 Video
                </Radio>
                <Radio value="image" style={{ color: "white" }}>
                  Image
                </Radio>
              </Radio.Group>
            </div>
            <Input
              placeholder="Title"
              className="mt-5 mb-2"
              style={{ textAlign: "center", width: "324px" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Row
              justify={"start"}
              align={"middle"}
              style={{ width: "324px", fontWeight: "bold" }}
              className="mb-2"
            >
              <div>Location</div>
            </Row>
            <Radio.Group
              value={locationType}
              onChange={(e) => setLocationType(e.target.value)}
            >
              <Radio value={1} style={{ color: "white" }}>
                Preassigned Location
              </Radio>
              <Radio value={2} style={{ color: "white" }}>
                Geo-Anchor
              </Radio>
            </Radio.Group>

            {locationType === 1 && (
              <Row
                justify={"start"}
                align={"middle"}
                style={{ width: "324px", fontWeight: "bold" }}
                className="mt-2 mb-2"
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Choose a Location"
                  optionFilterProp="children"
                  onChange={(v: string) => setSelectedLocationId(v)}
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={orgLocationIds?.map(({ id, locationName }) => ({
                    label: locationName,
                    value: id,
                  }))}
                  loading={orgLocationIds === undefined}
                />
              </Row>
            )}

            {locationType === 2 && (
              <>
                <div className="z-50 mt-5">
                  <Input
                    placeholder="Add a Location"
                    style={{ textAlign: "center", width: "324px" }}
                    {...address}
                  />
                  {address.suggestions?.length > 0 && (
                    <div
                      style={{ width: "324px" }}
                      className="absolute bg-white mt-2 rounded-b-lg"
                    >
                      <List
                        className="p-2"
                        dataSource={address.suggestions}
                        renderItem={(suggestion) => (
                          <List.Item
                            className="cursor-pointer"
                            onClick={() => {
                              address.setValue(suggestion.place_name);
                              address.setSuggestions([]);
                              handleSelectedPlace(suggestion);
                            }}
                          >
                            {suggestion.place_name}
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MapViewSimple
                    selectedPlace={selectedPlace}
                    setLatLng={setLatLng}
                    lat={lat}
                    lng={lng}
                  />
                </div>
              </>
            )}

            <TextArea
              rows={4}
              placeholder="Description"
              style={{ textAlign: "center", width: "324px" }}
              className="mt-5"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Row>

          <Row justify={"center"} align={"middle"} className="mt-5 mb-1">
            <div style={{ width: "320px", color: "white" }}>
              <Typography.Text
                style={{ color: "white", fontSize: 14, fontWeight: 5 }}
              >
                Call To Action Button(Optional)
              </Typography.Text>
            </div>
          </Row>

          <Row justify={"center"} align={"middle"}>
            <input
              type="text"
              value={callActionButtonText}
              onChange={(e) => setCallActionButtonText(e.target.value)}
              style={{ width: "280px" }}
              className="h-[44px]
        bg-[#00000066] rounded-[24px]
        text-white border-none
        outline-none pl-[24px] pr-[24px]
        backdrop-blur-sm backdrop-brightness-[100%]
        [-webkit-backdrop-filter:blur(4px)_brightness(100%)"
            />
          </Row>

          <Row justify={"center"} align={"middle"} className="mt-1 mb-1">
            <div style={{ width: "320px", color: "white" }}>
              <Typography.Text
                style={{ color: "white", fontSize: 14, fontWeight: 5 }}
              >
                Links to
              </Typography.Text>
            </div>
          </Row>

          <Row justify={"center"} align={"middle"}>
            <div style={{ position: "relative", width: "280px" }}>
              <input
                pattern="^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$"
                type="text"
                value={callActionButtonLink}
                onChange={(e) => {
                  if (e.target.value.length < "https://".length) return;
                  setCallActionButtonLink(
                    "https://" +
                      (callActionButtonLink != e.target.value
                        ? e.target.value.split("https://").pop()
                        : "")
                  );
                }}
                style={{ width: "280px", marginLeft: "-24px" }}
                className="h-[44px]
        bg-[#00000066] rounded-[24px]
        text-white border-none
        outline-none pl-[24px] pr-[24px]
        backdrop-blur-sm backdrop-brightness-[100%]
        [-webkit-backdrop-filter:blur(4px)_brightness(100%)"
              />
              <FaLink
                style={{
                  position: "absolute",
                  right: "-15px",
                  top: "22px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
          </Row>
          {!URL_REGEX.test(callActionButtonLink) &&
            callActionButtonLink != "https://" && (
              <Row justify={"center"} align={"middle"} style={{ color: "red" }}>
                Please enter a valid url
              </Row>
            )}

          <Row justify={"center"} align={"middle"}>
            {!uploading ? (
              <Button
                shape="round"
                style={{ marginTop: 30 }}
                disabled={
                  fileList.length === 0 ||
                  !description ||
                  !title ||
                  !srcType ||
                  (locationType === 2 && (!lat || !lng)) ||
                  (locationType === 1 && !selectedLocationId) ||
                  !srcType ||
                  (!URL_REGEX.test(callActionButtonLink) &&
                    callActionButtonLink !== "https://")
                }
                onClick={handleUpload}
              >
                <b>Upload</b>
              </Button>
            ) : (
              <Progress strokeLinecap="butt" percent={uploadProgress} />
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
